import { vendorTypes } from "redux/Ldb/types";

const initialState = {
    joiningWaitList: false,
    joinWaitListSuccess: null,
    joinWaitListFailure: null,
    fetchingLdbVendors: false,
    fetchedLdbVendorsSuccess: null,
    fetchedLdbVendorsFailure: null,
    fetchingSingleVendor: false,
    fetchedSingleVendorSuccess: null,
    fetchedSingleVendorFailure: null,
    authenticatingGmail: false,
    authenticateGmailSuccess: null,
    authenticateGmailFailure: null,
    connectingGoogleWorkspace: false,
    connectGoogleWorkspaceSuccess: null,
    connectGoogleWorkspaceFailure: null,
    fetchingAllChannels: false,
    fetchedAllChannelsSuccess: null,
    fetchedAllChannelsFailure: null,
    fetchingVendorsByOrderType: false,
    fetchedVendorsByOrderTypeSuccess: null,
    fetchedVendorsByOrderTypeFailure: null,
    fetchingOnboardedVendors: false,
    fetchedOnboardedVendorsSuccess: null,
    fetchedOnboardedVendorsFailure: null,
    fetchingSuggestedVendors: false,
    fetchedSuggestedVendorsSuccess: null,
    fetchedSuggestedVendorsFailure: null,
    authenticatingMicrosoft: false,
    authenticateMicrosoftSuccess: null,
    authenticateMicrosoftFailure: null,
    connectingMicrosoftWorkspace: false,
    connectMicrosoftWorkspaceSuccess: null,
    connectMicrosoftWorkspaceFailure: null,
};

export const vendor = (state = initialState, action) => {
    switch (action.type) {
        case vendorTypes.JOIN_WAITLIST_REQUEST:
            return {
                ...state,
                joiningWaitList: true,
                joinWaitListSuccess: null,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_SUCCESS:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: action.payload,
                joinWaitListFailure: null,
            };
        case vendorTypes.JOIN_WAITLIST_FAILURE:
            return {
                ...state,
                joiningWaitList: false,
                joinWaitListSuccess: null,
                joinWaitListFailure: action.payload,
            };

        case vendorTypes.FETCH_LDB_VENDORS_REQUEST:
            return {
                ...state,
                fetchingLdbVendors: true,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: action.payload,
                fetchedLdbVendorsFailure: null,
            };
        case vendorTypes.FETCH_LDB_VENDORS_FAILURE:
            return {
                ...state,
                fetchingLdbVendors: false,
                fetchedLdbVendorsSuccess: null,
                fetchedLdbVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SINGLE_VENDOR_REQUEST:
            return {
                ...state,
                fetchingSingleVendor: true,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: action.payload,
                fetchedSingleVendorFailure: null,
            };
        case vendorTypes.FETCH_SINGLE_VENDOR_FAILURE:
            return {
                ...state,
                fetchingSingleVendor: false,
                fetchedSingleVendorSuccess: null,
                fetchedSingleVendorFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_GMAIL_REQUEST:
            return {
                ...state,
                authenticatingGmail: true,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_SUCCESS:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: action.payload,
                authenticateGmailFailure: null,
            };
        case vendorTypes.AUTHENTICATE_GMAIL_FAILURE:
            return {
                ...state,
                authenticatingGmail: false,
                authenticateGmailSuccess: null,
                authenticateGmailFailure: action.payload,
            };

        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingGoogleWorkspace: true,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: action.payload,
                connectGoogleWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingGoogleWorkspace: false,
                connectGoogleWorkspaceSuccess: null,
                connectGoogleWorkspaceFailure: action.payload,
            };

        case vendorTypes.FETCH_ALL_CHANNELS_REQUEST:
            return {
                ...state,
                fetchingAllChannels: true,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_SUCCESS:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: action.payload,
                fetchedAllChannelsFailure: null,
            };
        case vendorTypes.FETCH_ALL_CHANNELS_FAILURE:
            return {
                ...state,
                fetchingAllChannels: false,
                fetchedAllChannelsSuccess: null,
                fetchedAllChannelsFailure: action.payload,
            };

        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST:
            return {
                ...state,
                fetchingVendorsByOrderType: true,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: action.payload,
                fetchedVendorsByOrderTypeFailure: null,
            };
        case vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE:
            return {
                ...state,
                fetchingVendorsByOrderType: false,
                fetchedVendorsByOrderTypeSuccess: null,
                fetchedVendorsByOrderTypeFailure: action.payload,
            };

        case vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingOnboardedVendors: true,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: action.payload,
                fetchedOnboardedVendorsFailure: null,
            };
        case vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingOnboardedVendors: false,
                fetchedOnboardedVendorsSuccess: null,
                fetchedOnboardedVendorsFailure: action.payload,
            };

        case vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST:
            return {
                ...state,
                fetchingSuggestedVendors: true,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: action.payload,
                fetchedSuggestedVendorsFailure: null,
            };
        case vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE:
            return {
                ...state,
                fetchingSuggestedVendors: false,
                fetchedSuggestedVendorsSuccess: null,
                fetchedSuggestedVendorsFailure: action.payload,
            };

        case vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST:
            return {
                ...state,
                authenticatingMicrosoft: true,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: action.payload,
                authenticateMicrosoftFailure: null,
            };
        case vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE:
            return {
                ...state,
                authenticatingMicrosoft: false,
                authenticateMicrosoftSuccess: null,
                authenticateMicrosoftFailure: action.payload,
            };

        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST:
            return {
                ...state,
                connectingMicrosoftWorkspace: true,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: action.payload,
                connectMicrosoftWorkspaceFailure: null,
            };
        case vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE:
            return {
                ...state,
                connectingMicrosoftWorkspace: false,
                connectMicrosoftWorkspaceSuccess: null,
                connectMicrosoftWorkspaceFailure: action.payload,
            };

        default:
            return state;
    }
};
