import { useEffect, useState } from "react";
import { authService } from "services/Ldb";
import { getFromStorage, removeFromStorage, saveToStorage } from "helpers";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { authActions } from "redux/Ldb/actions";

export const PrivateRoutes = ({ children }) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const redirectUrl = getFromStorage("redirect-url");

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-user"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);

        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (redirectUrl && location.pathname !== `/new-onboard`) {
        removeFromStorage("redirect-url");
    }

    if (!authService.loggedIn()) {
        if (!authService.loggedIn() && Boolean(profile?.user)) {
            dispatch(authActions.logOut());
        }

        saveToStorage("redirect-url", location.pathname + location?.search);
        window.open("/login", "_self");
        return;
    }

    if (
        authService.loggedIn() &&
        !profile?.user?.active &&
        profile?.token &&
        // !profile?.verifyComplete &&
        location.pathname !== `/registration-success/${profile?.token}`
    ) {
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }

    return children;
};
