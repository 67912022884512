import config from "config/config";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { authActions, orderActions, vendorActions } from "redux/Ldb/actions";
import { io } from "socket.io-client";
import { sidebarLinks } from "variables/sidebarLinks";
import ModalContainer from "./ModalContainer";
import Button from "./Button";
import Checkbox from "./InputField/Checkbox";
import { toast } from "react-hot-toast";
import CustomToast from "./CustomToast";
import { isEqual } from "lodash";
import ProgressBar from "./ProgressBar";

const socket = io(`${config.API_URL}`);

const DashboardSidebar = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const [isHoveredId, setIsHoveredId] = useState(-1);
    const [profile, setProfile] = useState({});
    const [activeSideLink, setActiveSideLink] = useState("");
    const [activeSidebarId, setActiveSidebarId] = useState(-1);
    const [syncedVendors, setSyncedVendors] = useState({});
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [totalPercentOne, setTotalPercentOne] = useState(0);
    const [totalPercentTwo, setTotalPercentTwo] = useState(0);
    const [totalPercentThree, setTotalPercentThree] = useState(0);
    const [rfqs, setRfqs] = useState([]);
    const [teams, setTeams] = useState([]);
    const [openSyncedModal, setOpenSyncedModal] = useState(false);
    const [showSideBarUpdate, setShowSideBarUpdate] = useState(true);
    const [showImporting, setShowImporting] = useState(false);

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { fetchedAllChannelsSuccess, connectGoogleWorkspaceSuccess, fetchedLdbVendorsSuccess } =
        useSelector((state) => state.vendor);
    const { addingVendor, addVendorSuccess, fetchUserProfileSuccess, fetchedTeamMemberSuccess } =
        useSelector((state) => state.auth);
    const { fetchedRfqsSuccess } = useSelector((state) => state.order);

    const channelsSynced = fetchedAllChannelsSuccess?.channel?.map(
        (channel) => channel?.synced && channel?.active
    );

    useEffect(() => {
        dispatch(authActions.getTeamMembers());
        dispatch(vendorActions.getLdbVendors(10));
        dispatch(orderActions.getRfqs());
    }, [dispatch]);

    useEffect(() => {
        if (Boolean(fetchedLdbVendorsSuccess)) {
            setVendors(fetchedLdbVendorsSuccess?.vendors);
        }
    }, [fetchedLdbVendorsSuccess]);

    useEffect(() => {
        if (Boolean(fetchedTeamMemberSuccess)) {
            setTeams(fetchedTeamMemberSuccess?.teamMembers);
        }
    }, [fetchedTeamMemberSuccess]);

    useEffect(() => {
        if (Boolean(fetchedRfqsSuccess)) {
            setRfqs(fetchedRfqsSuccess?.rfqs);
        }
    }, [fetchedRfqsSuccess]);

    useEffect(() => {
        if (vendors.length > 0) {
            setTotalPercentOne(1);
        }
    }, [vendors]);

    useEffect(() => {
        if (rfqs.length > 0) {
            setTotalPercentTwo(1);
        }
    }, [rfqs]);
    useEffect(() => {
        if (teams.length > 0) {
            setTotalPercentThree(1);
        }
    }, [teams]);

    const LinkItem = ({ item, idx }) => {
        const openChildren =
            typeof item?.link === "string"
                ? item?.link.includes(activeSideLink) || isEqual(item?.link, activeSideLink)
                : item?.link
                      ?.map((path) => activeSideLink?.startsWith?.(path))
                      ?.find((value) => Boolean(value));

        return !item?.children ? (
            <NavLink
                to={item.link}
                onClick={() => {
                    setActiveSideLink(item.link);
                    setActiveSidebarId(idx);
                }}
                end
                onMouseEnter={() => setIsHoveredId(idx)}
                onMouseLeave={() => setIsHoveredId(-1)}
                className={({ isActive }) =>
                    `flex ${sidebarCollapsed ? "px-1" : "px-3"} py-2.5 hover:bg-slate-50 ${
                        isActive && "bg-slate-50"
                    } cursor-pointer`
                }
            >
                {({ isActive }) => (
                    <div className="flex gap-2 items-center">
                        {item?.icons && (
                            <i
                                className={`${item.icons} ${
                                    isHoveredId === idx ? "text-g-60" : "text-slate-500"
                                } ${isActive && "!text-g-60"} `}
                            ></i>
                        )}
                        {!sidebarCollapsed && (
                            <p
                                className={`text-sm ${
                                    sidebarCollapsed ? "opacity-0" : "opacity-1"
                                } ${isHoveredId === idx ? "text-g-60" : "text-slate-500"} ${
                                    isActive && "!text-g-60"
                                } font-rocGroteskMedium`}
                            >
                                {item.title}
                            </p>
                        )}
                    </div>
                )}
            </NavLink>
        ) : (
            <div>
                <NavLink
                    to={item.link?.[0]}
                    onClick={() => {
                        setActiveSideLink(item.link?.[0]);
                        setActiveSidebarId(idx);
                        setSidebarCollapsed(false);
                    }}
                    onMouseEnter={() => setIsHoveredId(idx)}
                    onMouseLeave={() => setIsHoveredId(-1)}
                    className={`flex gap-2 items-center ${
                        sidebarCollapsed ? "px-1" : "px-3"
                    } py-2.5 hover:bg-slate-50 ${
                        (item?.link.includes(activeSideLink) || openChildren) && "bg-slate-50"
                    } cursor-pointer`}
                >
                    {item?.icons && (
                        <i
                            className={`${item.icons} ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${
                                (item?.link.includes(activeSideLink) || openChildren) &&
                                "!text-g-60"
                            }`}
                        ></i>
                    )}
                    {!sidebarCollapsed && (
                        <p
                            className={`text-sm ${sidebarCollapsed ? "opacity-0" : "opacity-1"} ${
                                isHoveredId === idx ? "text-g-60" : "text-slate-500"
                            } ${
                                (item?.link.includes(activeSideLink) || openChildren) &&
                                "!text-g-60"
                            } font-rocGroteskMedium`}
                        >
                            {item.title}
                        </p>
                    )}
                </NavLink>
                {(item?.link.includes(activeSideLink) ||
                    isEqual(item?.link, activeSideLink) ||
                    openChildren) &&
                    !sidebarCollapsed && (
                        <div className="pl-4 mt-1">
                            {item?.children?.map((child, index) => {
                                return (
                                    <LinkItem
                                        key={child?.title + index}
                                        item={child}
                                        idx={idx + "-" + index}
                                    />
                                );
                            })}
                        </div>
                    )}
            </div>
        );
    };

    const handleAddVendor = () => {
        if (selectedVendors && selectedVendors?.length > 0) {
            const body = {
                vendors: selectedVendors?.map((vendor) => ({
                    email: vendor?.vendor_email?.replace(" ", ""),
                    documents: [],
                    category: vendor?.vendor_category?.replace(" ", ""),
                })),
            };

            dispatch(authActions.addVendor(body));
        } else {
            toast.custom((t) => (
                <CustomToast t={t} message={"Please select at least one vendor"} type="success" />
            ));
        }
    };

    useEffect(() => {
        if (Boolean(addVendorSuccess)) {
            setOpenSyncedModal(false);
            dispatch(authActions.resetAddVendorSuccess());
        }
    }, [addVendorSuccess, dispatch]);

    useEffect(() => {
        socket.on("vendor-sync", (data) => {
            if (data?.ldbId === profile?._id) {
                setSyncedVendors(data);
                setShowImporting((prev) => !prev);
                setSelectedVendors(data?.vendors);
                setOpenSyncedModal(true);
            }
        });

        // Remove event listener on component unmount
        return () => socket.off("vendor-sync");
    }, [profile?._id]);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    useEffect(() => {
        const pathArr = location.pathname
            ?.split("/")
            ?.filter((str) => str !== "")
            ?.slice(1);

        if (pathArr?.length > 0) {
            setActiveSideLink("/" + pathArr?.join("/"));
        } else {
            setActiveSidebarId(0);
        }
    }, [location.pathname]);

    useEffect(() => {
        dispatch(vendorActions.getAllChannels());
    }, [dispatch, showImporting, connectGoogleWorkspaceSuccess]);

    useEffect(() => {
        const total = totalPercentOne + totalPercentTwo + totalPercentThree + 1;
        if (sidebarCollapsed) {
            setShowSideBarUpdate(false);
        }
        if (!sidebarCollapsed && Number(total) !== 4) {
            setShowSideBarUpdate(true);
        }
    }, [sidebarCollapsed, totalPercentOne, totalPercentTwo, totalPercentThree]);

    useEffect(() => {
        const total = totalPercentOne + totalPercentTwo + totalPercentThree + 1;
        if (Number(total) === 4) {
            setShowSideBarUpdate(false);
        } else {
            setShowSideBarUpdate(true);
        }
    }, [totalPercentOne, totalPercentTwo, totalPercentThree]);

    return (
        <>
            <div
                className={`${
                    sidebarCollapsed ? "w-[58px] !pl-lg" : "w-full "
                }  bg-white py-6 px-4 cursor-pointer relative flex flex-col justify-between gap-y-1 border-r border-slate-100 h-full`}
            >
                <div
                    onClick={() => setSidebarCollapsed((prev) => !prev)}
                    className={`flex   ${
                        sidebarCollapsed && "rotate-180"
                    } z-30 items-center absolute bg-white right-[-14px] top-[14px] cursor-pointer justify-center w-[28px] h-[28px] px-1.5 rounded-full border border-n-20 shadow-boxShadow`}
                >
                    <i className="ri-layout-left-2-line text-slate-400"></i>
                </div>
                <div className="flex  flex-col gap-y-1 mt-[-2px]">
                    <div className=" ">
                        {sidebarLinks?.map((item, idx) => {
                            return <LinkItem key={item.title + idx} item={item} idx={idx} />;
                        })}
                    </div>

                    {showSideBarUpdate && (
                        <div className="mt-[50px] pb-5 mb-5 border-[0.5px] p-[10px] rounded-[8px] border-g-65">
                            <div
                                className="flex justify-end cursor-pointer"
                                onClick={() => setShowSideBarUpdate(false)}
                            >
                                <i className="ri-close-fill text-slate-950 text-sm"></i>
                            </div>
                            <div>
                                <p className="text-sm text-gm-50 font-rocGroteskMedium">
                                    Get started with TradeAlly
                                </p>
                                <ProgressBar
                                    className="bg-carribean-green mt-1 !rounded-[5px]"
                                    progress={
                                        ((totalPercentOne +
                                            totalPercentTwo +
                                            totalPercentThree +
                                            1) /
                                            4) *
                                        100
                                    }
                                />
                                <p className="text-gm-40 text-xs font-rocGroteskMedium mt-1">
                                    {((totalPercentOne + totalPercentTwo + totalPercentThree + 1) /
                                        4) *
                                        100}
                                    % complete
                                </p>
                                <div className="mt-2">
                                    <div className="py-4 border-slate-100 border-b-[0.7px] text-g-70 flex items-center space-x-2">
                                        <i className="ri-checkbox-circle-fill  text-lg"></i>
                                        <p className="text-sm font-rocGroteskMedium">
                                            Add business details
                                        </p>
                                        <i className="ri-arrow-right-s-line text-lg"></i>
                                    </div>
                                    <div
                                        className={`py-4 border-slate-100 border-b-[0.7px] ${
                                            teams?.length > 0 ? "text-g-70" : "text-gm-35"
                                        }  flex items-center space-x-2 cursor-pointer`}
                                        onClick={() =>
                                            teams?.length > 0 &&
                                            navigate("/dashboard/team-management")
                                        }
                                    >
                                        {" "}
                                        <i
                                            className={`${
                                                teams?.length > 0
                                                    ? "ri-checkbox-circle-fill"
                                                    : "ri-checkbox-blank-circle-line"
                                            }  text-lg`}
                                        ></i>
                                        <p className="text-sm font-rocGroteskMedium">
                                            Add your team members
                                        </p>
                                        <i className="ri-arrow-right-s-line text-lg"></i>
                                    </div>
                                    <div
                                        className={`py-4 border-slate-100 border-b-[0.7px] ${
                                            vendors?.length > 0 ? "text-g-70" : "text-gm-35"
                                        }  flex items-center space-x-2`}
                                        onClick={() =>
                                            vendors?.length > 0 && navigate("/dashboard/vendors")
                                        }
                                    >
                                        <i
                                            className={`${
                                                vendors?.length > 0
                                                    ? "ri-checkbox-circle-fill"
                                                    : "ri-checkbox-blank-circle-line"
                                            }  text-lg`}
                                        ></i>
                                        <p className="text-sm font-rocGroteskMedium">Add vendors</p>
                                        <i className="ri-arrow-right-s-line text-lg"></i>
                                    </div>
                                    <div
                                        className={`py-4 border-slate-100 border-b-[0.7px] ${
                                            rfqs?.length > 0 ? "text-g-70" : "text-gm-35"
                                        }  flex items-center space-x-2`}
                                        onClick={() =>
                                            rfqs?.length > 0 && navigate("/dashboard/rfqs")
                                        }
                                    >
                                        {" "}
                                        <i
                                            className={`${
                                                rfqs?.length > 0
                                                    ? "ri-checkbox-circle-fill"
                                                    : "ri-checkbox-blank-circle-line"
                                            }  text-lg`}
                                        ></i>
                                        <p className="text-sm font-rocGroteskMedium">
                                            Request for a quote
                                        </p>
                                        <i className="ri-arrow-right-s-line text-lg"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {channelsSynced?.includes(false) && !sidebarCollapsed && (
                    <div className="shadow-boxShadow-3 w-full bg-white border border-slate-100 rounded py-2.5 px-3 mb-2">
                        <div className="flex items-center justify-between ">
                            <p className="text-gm-50 text-sm font-rocGroteskMedium">
                                Importing Contacts
                            </p>
                            {/* <i className="ri-close-fill text-lg"></i> */}
                        </div>
                        <p className="text-[13px] text-slate-500 font-rocGroteskMedium ">
                            Co-Pilot is trying to import your existing vendor contacts
                        </p>
                        {/* <p className="mt-2 flex items-center text-g-50 gap-1.5">
                    <span className="text-[13px] ">Try importing now</span>
                    <i className="ri-arrow-right-line text-sm"></i>
                </p> */}
                    </div>
                )}
            </div>
            <ModalContainer
                open={openSyncedModal && syncedVendors?.vendors?.length > 0}
                showCloseIcon={false}
                tailwindClassName="w-[64%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setOpenSyncedModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center p-6">
                            <p className="text-2xl mb-1 font-rocGroteskMedium text-g-75">
                                Select vendors to add to your list
                            </p>
                            <p className="text-sm font-rocGroteskMedium text-gm-40">
                                Add existing from your Gmail account to your vendor list to help you
                                get started
                            </p>
                        </div>
                        <div className="w-full max-h-[450px] overflow-auto">
                            {syncedVendors?.vendors?.map((vendor, index) => {
                                const isChecked = selectedVendors?.find(
                                    (selected) => selected?.vendor_email === vendor?.vendor_email
                                );

                                return (
                                    <div key={index} className="flex items-center w-full py-4">
                                        <div className="flex items-center gap-5 w-full">
                                            <div>
                                                <p className="text-base text-slate-700 font-rocGroteskMedium">
                                                    {vendor?.vendor_name}
                                                </p>
                                                <p className="text-base text-slate-500 font-rocGroteskMedium">
                                                    {vendor?.vendor_email}
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <Checkbox
                                                name="selectVendor"
                                                isChecked={Boolean(isChecked)}
                                                onChange={() => {
                                                    // if(isChecked){
                                                    const selectedVendorsCopy = [
                                                        ...selectedVendors,
                                                    ];
                                                    const idx = selectedVendorsCopy?.findIndex(
                                                        (selected) =>
                                                            selected?.vendor_email ===
                                                            vendor?.vendor_email
                                                    );

                                                    if (idx !== -1) {
                                                        selectedVendorsCopy?.splice(idx, 1);
                                                    } else {
                                                        selectedVendorsCopy.push(vendor);
                                                    }

                                                    setSelectedVendors(selectedVendorsCopy);
                                                    // }
                                                }}
                                                className={"!h-4 !w-4 !rounded-[4px] "}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="w-full py-6">
                            <Button
                                btnText={"Add vendor(s)"}
                                type={"button"}
                                onClick={handleAddVendor}
                                isLoading={addingVendor}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>

            <ModalContainer
                open={openSyncedModal && syncedVendors?.vendors?.length <= 0}
                showCloseIcon={false}
                tailwindClassName="w-[30%] max-lg:w-1/2 max-sm:w-[95%]"
                closeModal={() => {
                    setOpenSyncedModal(false);
                }}
            >
                <div className="bg-white rounded-lg shadow-cardShadow relative">
                    <div className="px-10 pt-3">
                        <div className="flex flex-col items-center text-center">
                            <div className="flex w-full justify-end mr-[-12px]">
                                <i
                                    onClick={() => setOpenSyncedModal(false)}
                                    className="ri-close-fill text-2xl"
                                ></i>
                            </div>
                            <div className="mb-4 relative">
                                <i className="ri-building-fill text-slate-400 text-[48px]"></i>
                                <i className="ri-close-circle-fill text-[21px] text-[#FF0000] absolute bottom-[4px] right-[-11.5px]"></i>
                            </div>
                            <p className="text-xl mb-3 text-center  font-rocGroteskMedium text-g-75">
                                No Vendors Detected in <br /> Connected Email Account
                            </p>
                            <p className="text-sm text-center font-rocGroteskMedium text-slate-500">
                                It appears that the connected email account does not contain vendor
                                information. Please ensure the email account has relevant vendor
                                data or consider connecting a different account if needed
                            </p>
                        </div>

                        <div className="w-full py-6">
                            <Button
                                btnText={"Invite vendors via email"}
                                type={"button"}
                                onClick={() => {
                                    setOpenSyncedModal(false);
                                    navigate("/dashboard/vendors/add-vendor?manual=true");
                                }}
                            />
                        </div>
                    </div>
                </div>
            </ModalContainer>
        </>
    );
};

export default DashboardSidebar;
