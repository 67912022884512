export const sidebarLinks = [
    {
        title: "Home",
        icons: "ri-home-3-line",
        link: "/dashboard",
    },
    {
        title: "Workflow",
        icons: "ri-flow-chart",
        link: "/dashboard/workflow",
    },
    {
        title: "My tasks",
        icons: "ri-task-line",
        link: "/dashboard/task",
    },
    // {
    //     title: "Co-pilot",
    //     icons: "ri-robot-line",
    //     link: "/dashboard/co-pilot",
    // },
    {
        title: "Vendor management",
        icons: "ri-building-line",
        link: [
            "/dashboard/vendors",
            "/dashboard/vendors/analytics",
            "/dashboard/vendor",
            "/vendors",
            "/vendors/analytics",
            "/vendor",
        ],
        children: [
            {
                title: "Vendor list",
                link: "/dashboard/vendors",
            },
            // {
            //     title: "Vendor analytics",
            //     link: "/dashboard/vendors/analytics",
            // },
            // {
            //     title: "Item title",
            //     link: "/dashboard/vendors/itemtitle",
            // },
        ],
    },
    {
        title: "Orders",
        icons: "ri-file-list-3-line",
        link: [
            "/dashboard/rfqs",
            "/dashboard/quotes",
            "/dashboard/purchase-orders",
            "/dashboard/rfq",
            "/rfqs",
            "/quotes",
            "/purchase-orders",
            "/rfq",
        ],
        children: [
            {
                title: "RFQs",
                link: "/dashboard/rfqs",
            },
            {
                title: "Quotes",
                link: "/dashboard/quotes",
            },
            {
                title: "Purchase Orders",
                link: "/dashboard/purchase-orders",
            },
        ],
    },
    {
        title: "Shipment",
        icons: "ri-box-1-line",
        link: "/dashboard/shipment",
    },
    // {
    //     title: "Business analytics",
    //     icons: "ri-slideshow-line",
    //     link: "/dashboard/test5",
    // },
    {
        title: "Teams",
        icons: "ri-team-line",
        link: "/dashboard/team-management",
    },
    // {
    //     title: "Settings",
    //     icons: "ri-settings-4-line",
    //     link: "/dashboard/settings",
    // },
];
