import { workFlowTypes } from "redux/Ldb/types";

const initialState = {
    fetchingWorkFlowList: false,
    fetchedWorkFlowListSuccess: null,
    fetchedWorkFlowListFailure: null,
    creatingWorkFlow: false,
    createWorkFlowSuccess: null,
    createWorkFlowFailure: null,
    deleteWorkFlow: false,
    deleteWorkFlowSuccess: null,
    deleteWorkFlowFailure: null,
    playOrPauseWorkFlow: false,
    playOrPauseWorkFlowSuccess: null,
    playOrPauseWorkFlowFailure: null,
    creatingEventAndAction: false,
    createEventAndActionSuccess: null,
    createEventAndActionFailure: null,
    fetchingSingleWorkflow: false,
    fetchedSingleWorkflowSuccess: null,
    fetchedSingleWorkflowFailure: null,
    updatingWorkflowConnections: false,
    updateWorkflowConnectionsSuccess: null,
    updateWorkflowConnectionsFailure: null,
    updatingWorkflowDisconnections: false,
    updateWorkflowDisconnectionsSuccess: null,
    updateWorkflowDisconnectionsFailure: null,
    updatingWorkflowNodePosition: false,
    updateWorkflowNodePositionSuccess: null,
    updateWorkflowNodePositionFailure: null,
    creatingComment: false,
    createCommentSuccess: null,
    createCommentFailure: null,
    fetchingNodeComments: false,
    fetchedNodeCommentsSuccess: null,
    fetchedNodeCommentsFailure: null,
    updatingEventsAndActions: false,
    updateEventsAndActionsSuccess: null,
    updateEventsAndActionsFailure: null,
    deletingEvent: false,
    deleteEventSuccess: null,
    deleteEventFailure: null,
    deletingAction: false,
    deleteActionSuccess: null,
    deleteActionFailure: null,
    swappingNode: false,
    swapNodeSuccess: null,
    swapNodeFailure: null,
    fetchingEventAndActionOptionList: false,
    fetchedEventAndActionOptionListSuccess: null,
    fetchedEventAndActionOptionListFailure: null,
    fetchingWorkflowActivities: false,
    fetchedWorkflowActivitiesSuccess: null,
    fetchedWorkflowActivitiesFailure: null,
    duplicatingWorkflow: false,
    duplicateWorkflowSuccess: null,
    duplicateWorkflowFailure: null,
    updatingWorkflowName: false,
    updateWorkflowNameSuccess: null,
    updateWorkflowNameFailure: null,
    fetchingWorkflowRuns: false,
    fetchedWorkflowRunSuccess: null,
    fetchedWorkflowRunFailure: null,
    fetchingSingleWorkflowRun: false,
    fetchedSingleWorkflowRunSuccess: null,
    fetchedSingleWorkflowRunFailure: null,
    retryingAction: false,
    retryActionSuccess: null,
    retryActionFailure: null,
    markingActionAsDone: false,
    markActionAsDoneSuccess: null,
    markActionAsDoneFailure: null,
    togglingPausePlayWorkflowRun: false,
    togglePausePlayWorkflowRunSuccess: null,
    togglePausePlayWorkflowRunFailure: null,
    fetchingWorkflowAnalytics: false,
    fetchedWorkflowAnalyticsSuccess: null,
    fetchedWorkflowAnalyticsFailure: null,
    fetchingDashboardWorkflowRuns: false,
    fetchedDashboardWorkflowRunsSuccess: null,
    fetchedDashboardWorkflowRunsFailure: null,
};

export const workFlow = (state = initialState, action) => {
    switch (action.type) {
        case workFlowTypes.FETCH_WORKFLOW_LIST_REQUEST:
            return {
                ...state,
                fetchingWorkFlowList: true,
                fetchedWorkFlowListSuccess: null,
                fetchedWorkFlowListFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_LIST_SUCCESS:
            return {
                ...state,
                fetchingWorkFlowList: false,
                fetchedWorkFlowListSuccess: action.payload,
                fetchedWorkFlowListFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_LIST_FAILURE:
            return {
                ...state,
                fetchingWorkFlowList: false,
                fetchedWorkFlowListSuccess: null,
                fetchedWorkFlowListFailure: action.payload,
            };
        case workFlowTypes.CREATE_WORKFLOW_REQUEST:
            return {
                ...state,
                creatingWorkFlow: true,
                createWorkFlowSuccess: null,
                createWorkFlowFailure: null,
            };
        case workFlowTypes.CREATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                creatingWorkFlow: false,
                createWorkFlowSuccess: action.payload,
                createWorkFlowFailure: null,
            };
        case workFlowTypes.CREATE_WORKFLOW_FAILURE:
            return {
                ...state,
                creatingWorkFlow: false,
                createWorkFlowSuccess: null,
                createWorkFlowFailure: action.payload,
            };
        case workFlowTypes.DELETE_WORKFLOW_REQUEST:
            return {
                ...state,
                deleteWorkFlow: true,
                deleteWorkFlowSuccess: null,
                deleteWorkFlowFailure: null,
            };
        case workFlowTypes.DELETE_WORKFLOW_SUCCESS:
            return {
                ...state,
                deleteWorkFlow: false,
                deleteWorkFlowSuccess: action.payload,
                deleteWorkFlowFailure: null,
            };
        case workFlowTypes.DELETE_WORKFLOW_FAILURE:
            return {
                ...state,
                deleteWorkFlow: false,
                deleteWorkFlowSuccess: null,
                deleteWorkFlowFailure: action.payload,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_REQUEST:
            return {
                ...state,
                playOrPauseWorkFlow: true,
                playOrPauseWorkFlowSuccess: null,
                playOrPauseWorkFlowFailure: null,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS:
            return {
                ...state,
                playOrPauseWorkFlow: false,
                playOrPauseWorkFlowSuccess: action.payload,
                playOrPauseWorkFlowFailure: null,
            };
        case workFlowTypes.PLAY_PAUSE_WORKFLOW_FAILURE:
            return {
                ...state,
                playOrPauseWorkFlow: false,
                playOrPauseWorkFlowSuccess: null,
                playOrPauseWorkFlowFailure: action.payload,
            };

        case workFlowTypes.CREATE_EVENT_AND_ACTION_REQUEST:
            return {
                ...state,
                creatingEventAndAction: true,
                createEventAndActionSuccess: null,
                createEventAndActionFailure: null,
            };
        case workFlowTypes.CREATE_EVENT_AND_ACTION_SUCCESS:
            return {
                ...state,
                creatingEventAndAction: false,
                createEventAndActionSuccess: action.payload,
                createEventAndActionFailure: null,
            };
        case workFlowTypes.CREATE_EVENT_AND_ACTION_FAILURE:
            return {
                ...state,
                creatingEventAndAction: false,
                createEventAndActionSuccess: null,
                createEventAndActionFailure: action.payload,
            };

        case workFlowTypes.FETCH_SINGLE_WORKFLOW_REQUEST:
            return {
                ...state,
                fetchingSingleWorkflow: true,
                fetchedSingleWorkflowSuccess: null,
                fetchedSingleWorkflowFailure: null,
            };
        case workFlowTypes.FETCH_SINGLE_WORKFLOW_SUCCESS:
            return {
                ...state,
                fetchingSingleWorkflow: false,
                fetchedSingleWorkflowSuccess: action.payload,
                fetchedSingleWorkflowFailure: null,
            };
        case workFlowTypes.FETCH_SINGLE_WORKFLOW_FAILURE:
            return {
                ...state,
                fetchingSingleWorkflow: false,
                fetchedSingleWorkflowSuccess: null,
                fetchedSingleWorkflowFailure: action.payload,
            };

        case workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_REQUEST:
            return {
                ...state,
                updatingWorkflowConnections: true,
                updateWorkflowConnectionsSuccess: null,
                updateWorkflowConnectionsFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_SUCCESS:
            return {
                ...state,
                updatingWorkflowConnections: false,
                updateWorkflowConnectionsSuccess: action.payload,
                updateWorkflowConnectionsFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_FAILURE:
            return {
                ...state,
                updatingWorkflowConnections: false,
                updateWorkflowConnectionsSuccess: null,
                updateWorkflowConnectionsFailure: action.payload,
            };

        case workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_REQUEST:
            return {
                ...state,
                updatingWorkflowDisconnections: true,
                updateWorkflowDisconnectionsSuccess: null,
                updateWorkflowDisconnectionsFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS:
            return {
                ...state,
                updatingWorkflowDisconnections: false,
                updateWorkflowDisconnectionsSuccess: action.payload,
                updateWorkflowDisconnectionsFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE:
            return {
                ...state,
                updatingWorkflowDisconnections: false,
                updateWorkflowDisconnectionsSuccess: null,
                updateWorkflowDisconnectionsFailure: action.payload,
            };

        case workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_REQUEST:
            return {
                ...state,
                updatingWorkflowNodePosition: true,
                updateWorkflowNodePositionSuccess: null,
                updateWorkflowNodePositionFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_SUCCESS:
            return {
                ...state,
                updatingWorkflowNodePosition: false,
                updateWorkflowNodePositionSuccess: action.payload,
                updateWorkflowNodePositionFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_FAILURE:
            return {
                ...state,
                updatingWorkflowNodePosition: false,
                updateWorkflowNodePositionSuccess: null,
                updateWorkflowNodePositionFailure: action.payload,
            };

        case workFlowTypes.CREATE_COMMENT_REQUEST:
            return {
                ...state,
                creatingComment: true,
                createCommentSuccess: null,
                createCommentFailure: null,
            };
        case workFlowTypes.CREATE_COMMENT_SUCCESS:
            return {
                ...state,
                creatingComment: false,
                createCommentSuccess: action.payload,
                createCommentFailure: null,
            };
        case workFlowTypes.CREATE_COMMENT_FAILURE:
            return {
                ...state,
                creatingComment: false,
                createCommentSuccess: null,
                createCommentFailure: action.payload,
            };

        case workFlowTypes.FETCH_NODE_COMMENTS_REQUEST:
            return {
                ...state,
                fetchingNodeComments: true,
                fetchedNodeCommentsSuccess: null,
                fetchedNodeCommentsFailure: null,
            };
        case workFlowTypes.FETCH_NODE_COMMENTS_SUCCESS:
            return {
                ...state,
                fetchingNodeComments: false,
                fetchedNodeCommentsSuccess: action.payload,
                fetchedNodeCommentsFailure: null,
            };
        case workFlowTypes.FETCH_NODE_COMMENTS_FAILURE:
            return {
                ...state,
                fetchingNodeComments: false,
                fetchedNodeCommentsSuccess: null,
                fetchedNodeCommentsFailure: action.payload,
            };

        case workFlowTypes.UPDATE_EVENT_AND_ACTION_REQUEST:
            return {
                ...state,
                updatingEventsAndActions: true,
                updateEventsAndActionsSuccess: null,
                updateEventsAndActionsFailure: null,
            };
        case workFlowTypes.UPDATE_EVENT_AND_ACTION_SUCCESS:
            return {
                ...state,
                updatingEventsAndActions: false,
                updateEventsAndActionsSuccess: action.payload,
                updateEventsAndActionsFailure: null,
            };
        case workFlowTypes.UPDATE_EVENT_AND_ACTION_FAILURE:
            return {
                ...state,
                updatingEventsAndActions: false,
                updateEventsAndActionsSuccess: null,
                updateEventsAndActionsFailure: action.payload,
            };

        case workFlowTypes.DELETE_EVENT_REQUEST:
            return {
                ...state,
                deletingEvent: true,
                deleteEventSuccess: null,
                deleteEventFailure: null,
            };
        case workFlowTypes.DELETE_EVENT_SUCCESS:
            return {
                ...state,
                deletingEvent: false,
                deleteEventSuccess: action.payload,
                deleteEventFailure: null,
            };
        case workFlowTypes.DELETE_EVENT_FAILURE:
            return {
                ...state,
                deletingEvent: false,
                deleteEventSuccess: null,
                deleteEventFailure: action.payload,
            };

        case workFlowTypes.DELETE_ACTION_REQUEST:
            return {
                ...state,
                deletingAction: true,
                deleteActionSuccess: null,
                deleteActionFailure: null,
            };
        case workFlowTypes.DELETE_ACTION_SUCCESS:
            return {
                ...state,
                deletingAction: false,
                deleteActionSuccess: action.payload,
                deleteActionFailure: null,
            };
        case workFlowTypes.DELETE_ACTION_FAILURE:
            return {
                ...state,
                deletingAction: false,
                deleteActionSuccess: null,
                deleteActionFailure: action.payload,
            };

        case workFlowTypes.SWAP_NODE_REQUEST:
            return {
                ...state,
                swappingNode: true,
                swapNodeSuccess: null,
                swapNodeFailure: null,
            };
        case workFlowTypes.SWAP_NODE_SUCCESS:
            return {
                ...state,
                swappingNode: false,
                swapNodeSuccess: action.payload,
                swapNodeFailure: null,
            };
        case workFlowTypes.SWAP_NODE_FAILURE:
            return {
                ...state,
                swappingNode: false,
                swapNodeSuccess: null,
                swapNodeFailure: action.payload,
            };

        case workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_REQUEST:
            return {
                ...state,
                fetchingEventAndActionOptionList: true,
                fetchedEventAndActionOptionListSuccess: null,
                fetchedEventAndActionOptionListFailure: null,
            };
        case workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_SUCCESS:
            return {
                ...state,
                fetchingEventAndActionOptionList: false,
                fetchedEventAndActionOptionListSuccess: action.payload,
                fetchedEventAndActionOptionListFailure: null,
            };
        case workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_FAILURE:
            return {
                ...state,
                fetchingEventAndActionOptionList: false,
                fetchedEventAndActionOptionListSuccess: null,
                fetchedEventAndActionOptionListFailure: action.payload,
            };

        case workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_REQUEST:
            return {
                ...state,
                fetchingWorkflowActivities: true,
                fetchedWorkflowActivitiesSuccess: null,
                fetchedWorkflowActivitiesFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_SUCCESS:
            return {
                ...state,
                fetchingWorkflowActivities: false,
                fetchedWorkflowActivitiesSuccess: action.payload,
                fetchedWorkflowActivitiesFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_FAILURE:
            return {
                ...state,
                fetchingWorkflowActivities: false,
                fetchedWorkflowActivitiesSuccess: null,
                fetchedWorkflowActivitiesFailure: action.payload,
            };

        case workFlowTypes.DUPLICATE_WORKFLOW_REQUEST:
            return {
                ...state,
                duplicatingWorkflow: true,
                duplicateWorkflowSuccess: null,
                duplicateWorkflowFailure: null,
            };
        case workFlowTypes.DUPLICATE_WORKFLOW_SUCCESS:
            return {
                ...state,
                duplicatingWorkflow: false,
                duplicateWorkflowSuccess: action.payload,
                duplicateWorkflowFailure: null,
            };
        case workFlowTypes.DUPLICATE_WORKFLOW_FAILURE:
            return {
                ...state,
                duplicatingWorkflow: false,
                duplicateWorkflowSuccess: null,
                duplicateWorkflowFailure: action.payload,
            };

        case workFlowTypes.UPDATE_WORKFLOW_NAME_REQUEST:
            return {
                ...state,
                updatingWorkflowName: true,
                updateWorkflowNameSuccess: null,
                updateWorkflowNameFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_NAME_SUCCESS:
            return {
                ...state,
                updatingWorkflowName: false,
                updateWorkflowNameSuccess: action.payload,
                updateWorkflowNameFailure: null,
            };
        case workFlowTypes.UPDATE_WORKFLOW_NAME_FAILURE:
            return {
                ...state,
                updatingWorkflowName: false,
                updateWorkflowNameSuccess: null,
                updateWorkflowNameFailure: action.payload,
            };

        case workFlowTypes.FETCH_WORKFLOW_RUNS_REQUEST:
            return {
                ...state,
                fetchingWorkflowRuns: true,
                fetchedWorkflowRunSuccess: null,
                fetchedWorkflowRunFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_RUNS_SUCCESS:
            return {
                ...state,
                fetchingWorkflowRuns: false,
                fetchedWorkflowRunSuccess: action.payload,
                fetchedWorkflowRunFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_RUNS_FAILURE:
            return {
                ...state,
                fetchingWorkflowRuns: false,
                fetchedWorkflowRunSuccess: null,
                fetchedWorkflowRunFailure: action.payload,
            };

        case workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_REQUEST:
            return {
                ...state,
                fetchingSingleWorkflowRun: true,
                fetchedSingleWorkflowRunSuccess: null,
                fetchedSingleWorkflowRunFailure: null,
            };
        case workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_SUCCESS:
            return {
                ...state,
                fetchingSingleWorkflowRun: false,
                fetchedSingleWorkflowRunSuccess: action.payload,
                fetchedSingleWorkflowRunFailure: null,
            };
        case workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_FAILURE:
            return {
                ...state,
                fetchingSingleWorkflowRun: false,
                fetchedSingleWorkflowRunSuccess: null,
                fetchedSingleWorkflowRunFailure: action.payload,
            };

        case workFlowTypes.RETRY_WORKFLOW_ACTION_REQUEST:
            return {
                ...state,
                retryingAction: true,
                retryActionSuccess: null,
                retryActionFailure: null,
            };
        case workFlowTypes.RETRY_WORKFLOW_ACTION_SUCCESS:
            return {
                ...state,
                retryingAction: false,
                retryActionSuccess: action.payload,
                retryActionFailure: null,
            };
        case workFlowTypes.RETRY_WORKFLOW_ACTION_FAILURE:
            return {
                ...state,
                retryingAction: false,
                retryActionSuccess: null,
                retryActionFailure: action.payload,
            };

        case workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_REQUEST:
            return {
                ...state,
                markingActionAsDone: true,
                markActionAsDoneSuccess: null,
                markActionAsDoneFailure: null,
            };
        case workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_SUCCESS:
            return {
                ...state,
                markingActionAsDone: false,
                markActionAsDoneSuccess: action.payload,
                markActionAsDoneFailure: null,
            };
        case workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_FAILURE:
            return {
                ...state,
                markingActionAsDone: false,
                markActionAsDoneSuccess: null,
                markActionAsDoneFailure: action.payload,
            };

        case workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_REQUEST:
            return {
                ...state,
                togglingPausePlayWorkflowRun: true,
                togglePausePlayWorkflowRunSuccess: null,
                togglePausePlayWorkflowRunFailure: null,
            };
        case workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_SUCCESS:
            return {
                ...state,
                togglingPausePlayWorkflowRun: false,
                togglePausePlayWorkflowRunSuccess: action.payload,
                togglePausePlayWorkflowRunFailure: null,
            };
        case workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_FAILURE:
            return {
                ...state,
                togglingPausePlayWorkflowRun: false,
                togglePausePlayWorkflowRunSuccess: null,
                togglePausePlayWorkflowRunFailure: action.payload,
            };

        case workFlowTypes.FETCH_WORKFLOW_ANALYTICS_REQUEST:
            return {
                ...state,
                fetchingWorkflowAnalytics: true,
                fetchedWorkflowAnalyticsSuccess: null,
                fetchedWorkflowAnalyticsFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_ANALYTICS_SUCCESS:
            return {
                ...state,
                fetchingWorkflowAnalytics: false,
                fetchedWorkflowAnalyticsSuccess: action.payload,
                fetchedWorkflowAnalyticsFailure: null,
            };
        case workFlowTypes.FETCH_WORKFLOW_ANALYTICS_FAILURE:
            return {
                ...state,
                fetchingWorkflowAnalytics: false,
                fetchedWorkflowAnalyticsSuccess: null,
                fetchedWorkflowAnalyticsFailure: action.payload,
            };

        case workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_REQUEST:
            return {
                ...state,
                fetchingDashboardWorkflowRuns: true,
                fetchedDashboardWorkflowRunsSuccess: null,
                fetchedDashboardWorkflowRunsFailure: null,
            };
        case workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_SUCCESS:
            return {
                ...state,
                fetchingDashboardWorkflowRuns: false,
                fetchedDashboardWorkflowRunsSuccess: action.payload,
                fetchedDashboardWorkflowRunsFailure: null,
            };
        case workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_FAILURE:
            return {
                ...state,
                fetchingDashboardWorkflowRuns: false,
                fetchedDashboardWorkflowRunsSuccess: null,
                fetchedDashboardWorkflowRunsFailure: action.payload,
            };
        default:
            return state;
    }
};
