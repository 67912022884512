export const settingsTypes = {
    FETCH_PAYMENT_HISTORY_REQUEST: "FETCH_PAYMENT_HISTORY_REQUEST",
    FETCH_PAYMENT_HISTORY_SUCCESS: "FETCH_PAYMENT_HISTORY_SUCCESS",
    FETCH_PAYMENT_HISTORY_FAILURE: "FETCH_PAYMENT_HISTORY_FAILURE",
    FETCH_CREDIT_HISTORY_REQUEST: "FETCH_CREDIT_HISTORY_REQUEST",
    FETCH_CREDIT_HISTORY_SUCCESS: "FETCH_CREDIT_HISTORY_SUCCESS",
    FETCH_CREDIT_HISTORY_FAILURE: "FETCH_CREDIT_HISTORY_FAILURE",
    FETCH_CREDIT_STRIPE_SESSION_REQUEST: "FETCH_CREDIT_STRIPE_SESSION_REQUEST",
    FETCH_CREDIT_STRIPE_SESSION_SUCCESS: "FETCH_CREDIT_STRIPE_SESSION_SUCCESS",
    FETCH_CREDIT_STRIPE_SESSION_FAILURE: "FETCH_CREDIT_STRIPE_SESSION_FAILURE",
    FETCH_PLANS_REQUEST: "FETCH_PLANS_REQUEST",
    FETCH_PLANS_SUCCESS: "FETCH_PLANS_SUCCESS",
    FETCH_PLANS_FAILURE: "FETCH_PLANS_FAILURE",
    FETCH_CURRENT_SUBSCRIPTION_REQUEST: "FETCH_CURRENT_SUBSCRIPTION_REQUEST",
    FETCH_CURRENT_SUBSCRIPTION_SUCCESS: "FETCH_CURRENT_SUBSCRIPTION_SUCCESS",
    FETCH_CURRENT_SUBSCRIPTION_FAILURE: "FETCH_CURRENT_SUBSCRIPTION_FAILURE",
    CREATE_STRIPE_SESSION_REQUEST: "CREATE_STRIPE_SESSION_REQUEST",
    CREATE_STRIPE_SESSION_SUCCESS: "CREATE_STRIPE_SESSION_SUCCESS",
    CREATE_STRIPE_SESSION_FAILURE: "CREATE_STRIPE_SESSION_FAILURE",
    UPDATE_STRIPE_SESSION_REQUEST: "UPDATE_STRIPE_SESSION_REQUEST",
    UPDATE_STRIPE_SESSION_SUCCESS: "UPDATE_STRIPE_SESSION_SUCCESS",
    UPDATE_STRIPE_SESSION_FAILURE: "UPDATE_STRIPE_SESSION_FAILURE",
    FETCH_STRIPE_SESSION_REQUEST: "FETCH_STRIPE_SESSION_REQUEST",
    FETCH_STRIPE_SESSION_SUCCESS: "FETCH_STRIPE_SESSION_SUCCESS",
    FETCH_STRIPE_SESSION_FAILURE: "FETCH_STRIPE_SESSION_FAILURE",
};
