import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const taskService = {
    createTask,
    updateTask,
    getTaskList,
};

async function createTask(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task/create`,
        requestOptions
    );
    return res;
}

async function updateTask(data, taskId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task/update/${taskId}`,
        requestOptions
    );
    return res;
}

async function getTaskList(limit, status, keyword) {
    const requestOptions = {
        method: "GET",
    };

    const urlParams = createUrlSearchParams({
        status,
        keyword
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/task?limit=${limit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}
