import CustomToast from "components/common/CustomToast";
import { toast } from "react-hot-toast";
import { settingsTypes } from "redux/Ldb/types";
import { settingsService } from "services/Ldb";

export const settingsActions = {
    getPaymentHistory,
    getSubscriptionPlan,
    getCreditHistory,
    createStripeSession,
    getStripeSession,
    resetGetStripeSession,
    resetCreateStripeSession,
    updateStripeSession,
    resetUpdateStripeSession,
    fetchCurrentSubscriptionPlan,
};

function getPaymentHistory() {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_PAYMENT_HISTORY_REQUEST));
        settingsService.fetchPaymentHistory().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_PAYMENT_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_PAYMENT_HISTORY_FAILURE, error?.message));
                }
            }
        );
    };
}
function getCreditHistory() {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_CREDIT_HISTORY_REQUEST));
        settingsService.fetchCreditUsageHistory().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CREDIT_HISTORY_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_CREDIT_HISTORY_FAILURE, error?.message));
                }
            }
        );
    };
}
function getSubscriptionPlan(interval) {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_PLANS_REQUEST));
        settingsService.fetchSubscriptionPlan(interval).then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_PLANS_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.FETCH_PLANS_FAILURE, error?.message));
                }
            }
        );
    };
}

function fetchCurrentSubscriptionPlan() {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_REQUEST));
        settingsService.fetchCurrentSubscription().then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    console.log("error", error?.message)
                    dispatch(
                        failure(settingsTypes.FETCH_CURRENT_SUBSCRIPTION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function createStripeSession(body) {
    return (dispatch) => {
        dispatch(request(settingsTypes.CREATE_STRIPE_SESSION_REQUEST));
        settingsService.createStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.CREATE_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.CREATE_STRIPE_SESSION_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateStripeSession(body) {
    return (dispatch) => {
        dispatch(request(settingsTypes.UPDATE_STRIPE_SESSION_REQUEST));
        settingsService.updateStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.UPDATE_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(settingsTypes.UPDATE_STRIPE_SESSION_FAILURE, error?.message));
                }
            }
        );
    };
}

function getStripeSession(body) {
    return (dispatch) => {
        dispatch(request(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_REQUEST));
        settingsService.fetchCreditStripeSession(body).then(
            (res) => {
                dispatch(success(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetCreateStripeSession() {
    return (dispatch) => {
        dispatch(success(settingsTypes.CREATE_STRIPE_SESSION_SUCCESS, null));
    };
}

function resetUpdateStripeSession() {
    return (dispatch) => {
        dispatch(success(settingsTypes.UPDATE_STRIPE_SESSION_SUCCESS, null));
    };
}

function resetGetStripeSession() {
    return (dispatch) => {
        dispatch(success(settingsTypes.FETCH_CREDIT_STRIPE_SESSION_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}

function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
