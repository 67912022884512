export const workFlowTypes = {
    FETCH_WORKFLOW_LIST_REQUEST: "FETCH_WORKFLOW_LIST_REQUEST",
    FETCH_WORKFLOW_LIST_SUCCESS: "FETCH_WORKFLOW_LIST_SUCCESS",
    FETCH_WORKFLOW_LIST_FAILURE: "FETCH_WORKFLOW_LIST_FAILURE",
    CREATE_WORKFLOW_REQUEST: "CREATE_WORKFLOW_REQUEST",
    CREATE_WORKFLOW_SUCCESS: "CREATE_WORKFLOW_SUCCESS",
    CREATE_WORKFLOW_FAILURE: "CREATE_WORKFLOW_FAILURE",
    DELETE_WORKFLOW_REQUEST: "DELETE_WORKFLOW_REQUEST",
    DELETE_WORKFLOW_SUCCESS: "DELETE_WORKFLOW_SUCCESS",
    DELETE_WORKFLOW_FAILURE: "DELETE_WORKFLOW_FAILURE",
    PLAY_PAUSE_WORKFLOW_REQUEST: "PLAY_PAUSE_WORKFLOW_REQUEST",
    PLAY_PAUSE_WORKFLOW_SUCCESS: "PLAY_PAUSE_WORKFLOW_SUCCESS",
    PLAY_PAUSE_WORKFLOW_FAILURE: "PLAY_PAUSE_WORKFLOW_FAILURE",
    CREATE_EVENT_AND_ACTION_REQUEST: "CREATE_EVENT_AND_ACTION_REQUEST",
    CREATE_EVENT_AND_ACTION_SUCCESS: "CREATE_EVENT_AND_ACTION_SUCCESS",
    CREATE_EVENT_AND_ACTION_FAILURE: "CREATE_EVENT_AND_ACTION_FAILURE",
    FETCH_SINGLE_WORKFLOW_REQUEST: "FETCH_SINGLE_WORKFLOW_REQUEST",
    FETCH_SINGLE_WORKFLOW_SUCCESS: "FETCH_SINGLE_WORKFLOW_SUCCESS",
    FETCH_SINGLE_WORKFLOW_FAILURE: "FETCH_SINGLE_WORKFLOW_FAILURE",
    UPDATE_WORKFLOW_CONNECTIONS_REQUEST: "UPDATE_WORKFLOW_CONNECTIONS_REQUEST",
    UPDATE_WORKFLOW_CONNECTIONS_SUCCESS: "UPDATE_WORKFLOW_CONNECTIONS_SUCCESS",
    UPDATE_WORKFLOW_CONNECTIONS_FAILURE: "UPDATE_WORKFLOW_CONNECTIONS_FAILURE",
    UPDATE_WORKFLOW_DISCONNECTIONS_REQUEST: "UPDATE_WORKFLOW_DISCONNECTIONS_REQUEST",
    UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS: "UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS",
    UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE: "UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE",
    UPDATE_WORKFLOW_NODE_POSITION_REQUEST: "UPDATE_WORKFLOW_NODE_POSITION_REQUEST",
    UPDATE_WORKFLOW_NODE_POSITION_SUCCESS: "UPDATE_WORKFLOW_NODE_POSITION_SUCCESS",
    UPDATE_WORKFLOW_NODE_POSITION_FAILURE: "UPDATE_WORKFLOW_NODE_POSITION_FAILURE",
    CREATE_COMMENT_REQUEST: "CREATE_COMMENT_REQUEST",
    CREATE_COMMENT_SUCCESS: "CREATE_COMMENT_SUCCESS",
    CREATE_COMMENT_FAILURE: "CREATE_COMMENT_FAILURE",
    FETCH_NODE_COMMENTS_REQUEST: "FETCH_NODE_COMMENTS_REQUEST",
    FETCH_NODE_COMMENTS_SUCCESS: "FETCH_NODE_COMMENTS_SUCCESS",
    FETCH_NODE_COMMENTS_FAILURE: "FETCH_NODE_COMMENTS_FAILURE",
    UPDATE_EVENT_AND_ACTION_REQUEST: "UPDATE_EVENT_AND_ACTION_REQUEST",
    UPDATE_EVENT_AND_ACTION_SUCCESS: "UPDATE_EVENT_AND_ACTION_SUCCESS",
    UPDATE_EVENT_AND_ACTION_FAILURE: "UPDATE_EVENT_AND_ACTION_FAILURE",
    DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
    DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
    DELETE_EVENT_FAILURE: "DELETE_EVENT_FAILURE",
    DELETE_ACTION_REQUEST: "DELETE_ACTION_REQUEST",
    DELETE_ACTION_SUCCESS: "DELETE_ACTION_SUCCESS",
    DELETE_ACTION_FAILURE: "DELETE_ACTION_FAILURE",
    SWAP_NODE_REQUEST: "SWAP_NODE_REQUEST",
    SWAP_NODE_SUCCESS: "SWAP_NODE_SUCCESS",
    SWAP_NODE_FAILURE: "SWAP_NODE_FAILURE",
    FETCH_EVENT_AND_ACTION_OPTIONS_REQUEST: "FETCH_EVENT_AND_ACTION_OPTIONS_REQUEST",
    FETCH_EVENT_AND_ACTION_OPTIONS_SUCCESS: "FETCH_EVENT_AND_ACTION_OPTIONS_SUCCESS",
    FETCH_EVENT_AND_ACTION_OPTIONS_FAILURE: "FETCH_EVENT_AND_ACTION_OPTIONS_FAILURE",
    FETCH_WORKFLOW_ACTIVITIES_REQUEST: "FETCH_WORKFLOW_ACTIVITIES_REQUEST",
    FETCH_WORKFLOW_ACTIVITIES_SUCCESS: "FETCH_WORKFLOW_ACTIVITIES_SUCCESS",
    FETCH_WORKFLOW_ACTIVITIES_FAILURE: "FETCH_WORKFLOW_ACTIVITIES_FAILURE",
    DUPLICATE_WORKFLOW_REQUEST: "DUPLICATE_WORKFLOW_REQUEST",
    DUPLICATE_WORKFLOW_SUCCESS: "DUPLICATE_WORKFLOW_SUCCESS",
    DUPLICATE_WORKFLOW_FAILURE: "DUPLICATE_WORKFLOW_FAILURE",
    UPDATE_WORKFLOW_NAME_REQUEST: "UPDATE_WORKFLOW_NAME_REQUEST",
    UPDATE_WORKFLOW_NAME_SUCCESS: "UPDATE_WORKFLOW_NAME_SUCCESS",
    UPDATE_WORKFLOW_NAME_FAILURE: "UPDATE_WORKFLOW_NAME_FAILURE",
    FETCH_WORKFLOW_RUNS_REQUEST: "FETCH_WORKFLOW_RUNS_REQUEST",
    FETCH_WORKFLOW_RUNS_SUCCESS: "FETCH_WORKFLOW_RUNS_SUCCESS",
    FETCH_WORKFLOW_RUNS_FAILURE: "FETCH_WORKFLOW_RUNS_FAILURE",
    FETCH_SINGLE_WORKFLOW_RUN_REQUEST: "FETCH_SINGLE_WORKFLOW_RUN_REQUEST",
    FETCH_SINGLE_WORKFLOW_RUN_SUCCESS: "FETCH_SINGLE_WORKFLOW_RUN_SUCCESS",
    FETCH_SINGLE_WORKFLOW_RUN_FAILURE: "FETCH_SINGLE_WORKFLOW_RUN_FAILURE",
    RETRY_WORKFLOW_ACTION_REQUEST: "RETRY_WORKFLOW_ACTION_REQUEST",
    RETRY_WORKFLOW_ACTION_SUCCESS: "RETRY_WORKFLOW_ACTION_SUCCESS",
    RETRY_WORKFLOW_ACTION_FAILURE: "RETRY_WORKFLOW_ACTION_FAILURE",
    MARK_WORKFLOW_ACTION_AS_DONE_REQUEST: "MARK_WORKFLOW_ACTION_AS_DONE_REQUEST",
    MARK_WORKFLOW_ACTION_AS_DONE_SUCCESS: "MARK_WORKFLOW_ACTION_AS_DONE_SUCCESS",
    MARK_WORKFLOW_ACTION_AS_DONE_FAILURE: "MARK_WORKFLOW_ACTION_AS_DONE_FAILURE",
    PAUSE_PLAY_WORKFLOW_RUN_REQUEST: "PAUSE_PLAY_WORKFLOW_RUN_REQUEST",
    PAUSE_PLAY_WORKFLOW_RUN_SUCCESS: "PAUSE_PLAY_WORKFLOW_RUN_SUCCESS",
    PAUSE_PLAY_WORKFLOW_RUN_FAILURE: "PAUSE_PLAY_WORKFLOW_RUN_FAILURE",
    FETCH_WORKFLOW_ANALYTICS_REQUEST: "FETCH_WORKFLOW_ANALYTICS_REQUEST",
    FETCH_WORKFLOW_ANALYTICS_SUCCESS: "FETCH_WORKFLOW_ANALYTICS_SUCCESS",
    FETCH_WORKFLOW_ANALYTICS_FAILURE: "FETCH_WORKFLOW_ANALYTICS_FAILURE",
    FETCH_DASHBOARD_WORKFLOW_RUNS_REQUEST: "FETCH_DASHBOARD_WORKFLOW_RUNS_REQUEST",
    FETCH_DASHBOARD_WORKFLOW_RUNS_SUCCESS: "FETCH_DASHBOARD_WORKFLOW_RUNS_SUCCESS",
    FETCH_DASHBOARD_WORKFLOW_RUNS_FAILURE: "FETCH_DASHBOARD_WORKFLOW_RUNS_FAILURE",
};
