import CustomToast from "components/common/CustomToast";
import { workFlowTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { workFlowService } from "services/Ldb";

export const workFlowActions = {
    getworkFlowList,
    pauseOrPlayWorkFlow,
    resetPauseOrPlayWorkFlowSuccess,
    deleteWorkFlow,
    resetDeleteWorkFlowSuccess,
    createWorkflow,
    resetCreateWorkFlowSuccess,
    createEventAndAction,
    resetCreateEventAndActionSuccess,
    getSingleWorkflow,
    updateWorkflowConnections,
    resetUpdateWorkflowConnectionsSuccess,
    resetUpdateWorkflowConnectionsFailure,
    updateWorkflowDisconnections,
    resetUpdateWorkflowDisconnectionsSuccess,
    resetUpdateWorkflowDisconnectionsFailure,
    updateWorkflowNodePosition,
    resetUpdateWorkflowNodePositionSuccess,
    createComment,
    resetCreateCommentSuccess,
    getNodeComments,
    resetGetNodesCommentsSuccess,
    updateEventAndAction,
    resetUpdateEventAndActionSuccess,
    deleteEvent,
    resetDeleteEventSuccess,
    deleteAction,
    resetDeleteActionSuccess,
    swapNodes,
    resetSwapNodesSuccess,
    getEventAndActionsOptionList,
    getWorkflowActivities,
    resetGetWorkflowActivitiesSuccess,
    duplicateWorkflow,
    resetDuplicateWorkflowSuccess,
    updateWorkflowName,
    resetUpdateWorkflowNameSuccess,
    getWorkflowRuns,
    getSingleWorkflowRun,
    retryAction,
    markActionAsDone,
    togglePausePlayWorkflowRun,
    resetTogglePausePlayWorkflowRunSuccess,
    getWorkflowAnalytics,
    getDashboardWorkflowRuns,
};

function getworkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_LIST_REQUEST));

        workFlowService
            .getWorkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated)
            .then(
                (res) => {
                    dispatch(success(workFlowTypes.FETCH_WORKFLOW_LIST_SUCCESS, res?.data));
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(workFlowTypes.FETCH_WORKFLOW_LIST_FAILURE, error?.message)
                        );
                    }
                }
            );
    };
}

function getDashboardWorkflowRuns() {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_REQUEST));
        workFlowService
            .getDashboardWorkflowRuns()
            .then(
                (res) => {
                    dispatch(
                        success(workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_SUCCESS, res?.data)
                    );
                },
                (error) => {
                    if (error.message) {
                        toast.custom((t) => (
                            <CustomToast t={t} message={error?.message} type="error" />
                        ));
                        dispatch(
                            failure(
                                workFlowTypes.FETCH_DASHBOARD_WORKFLOW_RUNS_FAILURE,
                                error?.message
                            )
                        );
                    }
                }
            );
    };
}


function createWorkflow(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.CREATE_WORKFLOW_REQUEST));

        workFlowService.createWorkFlow(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.CREATE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function updateWorkflowConnections(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_REQUEST));

        workFlowService.updateWorkflowConnections(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Connection updated"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function updateWorkflowDisconnections(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_REQUEST));

        workFlowService.updateWorkflowDisconnections(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Connection deleted"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function updateWorkflowNodePosition(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_REQUEST));

        workFlowService.updateWorkflowNodePosition(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_SUCCESS, res?.data));
                // toast.custom((t) => (
                //     <CustomToast t={t} message={"Position updated"} type="success" />
                // ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(
                        failure(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_FAILURE, error.message)
                    );
                }
            }
        );
    };
}

function createEventAndAction(data, supressErrorMsg) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.CREATE_EVENT_AND_ACTION_REQUEST));

        workFlowService.createEventAndAction(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_EVENT_AND_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message && !supressErrorMsg) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.CREATE_EVENT_AND_ACTION_FAILURE, error.message));
                }
            }
        );
    };
}

function getSingleWorkflow(workflowId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_SINGLE_WORKFLOW_REQUEST));

        workFlowService.getSingleWorkflow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_SINGLE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.FETCH_SINGLE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function pauseOrPlayWorkFlow(workflowId, data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.PLAY_PAUSE_WORKFLOW_REQUEST));

        workFlowService.pauseOrPlayWorkFlow(workflowId, data).then(
            (res) => {
                dispatch(success(workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(workFlowTypes.PLAY_PAUSE_WORKFLOW_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteWorkFlow(workflowId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.DELETE_WORKFLOW_REQUEST));

        workFlowService.deleteWorkFlow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Workflow deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_WORKFLOW_FAILURE, error?.message));
                }
            }
        );
    };
}

function createComment(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.CREATE_COMMENT_REQUEST));

        workFlowService.createComment(data).then(
            (res) => {
                dispatch(success(workFlowTypes.CREATE_COMMENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.CREATE_COMMENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function getNodeComments(nodeId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_NODE_COMMENTS_REQUEST));

        workFlowService.getNodeComments(nodeId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_NODE_COMMENTS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.FETCH_NODE_COMMENTS_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateEventAndAction(eventId, data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_EVENT_AND_ACTION_REQUEST));

        workFlowService.updateWorkflowEvent(eventId, data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_EVENT_AND_ACTION_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Update successful"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.UPDATE_EVENT_AND_ACTION_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function deleteEvent(eventId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.DELETE_EVENT_REQUEST));

        workFlowService.deleteEvent(eventId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_EVENT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_EVENT_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteAction(actionId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.DELETE_ACTION_REQUEST));

        workFlowService.deleteAction(actionId).then(
            (res) => {
                dispatch(success(workFlowTypes.DELETE_ACTION_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DELETE_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function swapNodes(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.SWAP_NODE_REQUEST));

        workFlowService.swapNodes(data).then(
            (res) => {
                dispatch(success(workFlowTypes.SWAP_NODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.SWAP_NODE_FAILURE, error?.message));
                }
            }
        );
    };
}

function getEventAndActionsOptionList() {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_REQUEST));

        workFlowService.getEventAndActionsOptionList().then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(
                            workFlowTypes.FETCH_EVENT_AND_ACTION_OPTIONS_FAILURE,
                            error?.message
                        )
                    );
                }
            }
        );
    };
}

function getWorkflowActivities(workflowId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_REQUEST));

        workFlowService.getWorkflowActivities(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function duplicateWorkflow(workflowId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.DUPLICATE_WORKFLOW_REQUEST));

        workFlowService.duplicateWorkflow(workflowId).then(
            (res) => {
                dispatch(success(workFlowTypes.DUPLICATE_WORKFLOW_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.DUPLICATE_WORKFLOW_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateWorkflowName(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.UPDATE_WORKFLOW_NAME_REQUEST));

        workFlowService.updateWorkflowName(data).then(
            (res) => {
                dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NAME_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Workflow renamed successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.UPDATE_WORKFLOW_NAME_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleWorkflowRun(workflowRunId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_REQUEST));

        workFlowService.getSingleWorkflowRun(workflowRunId).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_SINGLE_WORKFLOW_RUN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getWorkflowRuns(workflowId, limit) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_RUNS_REQUEST));

        workFlowService.getWorkflowRuns(workflowId, limit).then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_RUNS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.FETCH_WORKFLOW_RUNS_FAILURE, error?.message));
                }
            }
        );
    };
}

function retryAction(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.RETRY_WORKFLOW_ACTION_REQUEST));

        workFlowService.retryAction(data).then(
            (res) => {
                dispatch(success(workFlowTypes.RETRY_WORKFLOW_ACTION_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Action retried"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(workFlowTypes.RETRY_WORKFLOW_ACTION_FAILURE, error?.message));
                }
            }
        );
    };
}

function markActionAsDone(data) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_REQUEST));

        workFlowService.markActionAsDone(data).then(
            (res) => {
                dispatch(success(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Action marked as done"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.MARK_WORKFLOW_ACTION_AS_DONE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function togglePausePlayWorkflowRun(data, runId) {
    return (dispatch) => {
        dispatch(request(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_REQUEST));

        workFlowService.playPauseWorkflowRun(data, runId).then(
            (res) => {
                dispatch(success(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getWorkflowAnalytics() {
    return (dispatch) => {
        dispatch(request(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_REQUEST));

        workFlowService.getWorkflowAnalytics().then(
            (res) => {
                dispatch(success(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(workFlowTypes.FETCH_WORKFLOW_ANALYTICS_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetTogglePausePlayWorkflowRunSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.PAUSE_PLAY_WORKFLOW_RUN_SUCCESS, null));
    };
}

function resetUpdateWorkflowNameSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NAME_SUCCESS, null));
    };
}

function resetDuplicateWorkflowSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.DUPLICATE_WORKFLOW_SUCCESS, null));
    };
}

function resetGetWorkflowActivitiesSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.FETCH_WORKFLOW_ACTIVITIES_SUCCESS, null));
    };
}

function resetSwapNodesSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.SWAP_NODE_SUCCESS, null));
    };
}

function resetDeleteActionSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.DELETE_ACTION_SUCCESS, null));
    };
}

function resetDeleteEventSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.DELETE_EVENT_SUCCESS, null));
    };
}

function resetUpdateEventAndActionSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_EVENT_AND_ACTION_SUCCESS, null));
    };
}

function resetGetNodesCommentsSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.FETCH_NODE_COMMENTS_SUCCESS, null));
    };
}

function resetCreateCommentSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.CREATE_COMMENT_SUCCESS, null));
    };
}

function resetDeleteWorkFlowSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.DELETE_WORKFLOW_SUCCESS, null));
    };
}

function resetCreateWorkFlowSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.CREATE_WORKFLOW_SUCCESS, null));
    };
}

function resetCreateEventAndActionSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.CREATE_EVENT_AND_ACTION_SUCCESS, null));
    };
}

function resetPauseOrPlayWorkFlowSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.PLAY_PAUSE_WORKFLOW_SUCCESS, null));
    };
}

function resetUpdateWorkflowConnectionsSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_SUCCESS, null));
    };
}

function resetUpdateWorkflowConnectionsFailure() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_CONNECTIONS_FAILURE, null));
    };
}

function resetUpdateWorkflowDisconnectionsSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_SUCCESS, null));
    };
}

function resetUpdateWorkflowDisconnectionsFailure() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_DISCONNECTIONS_FAILURE, null));
    };
}

function resetUpdateWorkflowNodePositionSuccess() {
    return (dispatch) => {
        dispatch(success(workFlowTypes.UPDATE_WORKFLOW_NODE_POSITION_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
