export const taskTypes = {
    CREATE_TASK_REQUEST: "CREATE_TASK_REQUEST",
    CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
    CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
    UPDATE_TASK_REQUEST: "UPDATE_TASK_REQUEST",
    UPDATE_TASK_SUCCESS: "UPDATE_TASK_SUCCESS",
    UPDATE_TASK_FAILURE: "UPDATE_TASK_FAILURE",
    FETCH_TASK_LIST_REQUEST: "FETCH_TASK_LIST_REQUEST",
    FETCH_TASK_LIST_SUCCESS: "FETCH_TASK_LIST_SUCCESS",
    FETCH_TASK_LIST_FAILURE: "FETCH_TASK_LIST_FAILURE",
};
