import { configureStore } from "@reduxjs/toolkit";
import { auth, vendor, order, chat, workFlow, team, shipment, settings, task } from "redux/Ldb/reducers";

const store = configureStore({
    reducer: {
        auth,
        vendor,
        order,
        chat,
        workFlow,
        team,
        shipment,
        settings,
        task
        
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: true, immutableCheck: true }),
});

export default store;
