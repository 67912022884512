import CustomToast from "components/common/CustomToast";
import { vendorTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { vendorService, websiteService } from "services/Ldb";

export const vendorActions = {
    joinWaitlist,
    resetJoinWaitListSuccess,
    getLdbVendors,
    getSingleVendor,
    authenticateGmail,
    resetAuthenticateGmailSuccess,
    connectGoogleWorkspace,
    getAllChannels,
    getVendorsByOrderType,
    getOnboardedVendors,
    getSuggestedVendors,
    resetGetSuggestedVendorsSuccess,
    resetConnectGoogleWorkspaceSuccess,
    authenticateMicrosoftAcct,
    connectMicrosoftWorkspace,
    resetAuthenticateMicrosoftSuccess,
    resetConnectMicrosoftWorkspaceSuccess,
};

function joinWaitlist(data) {
    return (dispatch) => {
        dispatch(request(vendorTypes.JOIN_WAITLIST_REQUEST));
        websiteService.joinWaitList(data).then(
            (res) => {
                dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, res?.data));
            },
            (error) => {
                if (error?.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.JOIN_WAITLIST_FAILURE, error?.message));
                }
            }
        );
    };
}

function getLdbVendors(vendorLimit, search) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_LDB_VENDORS_REQUEST));

        vendorService.getLdbVendors(vendorLimit, search).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_LDB_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_LDB_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getOnboardedVendors() {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_ONBOARDED_VENDORS_REQUEST));

        vendorService.getOnboardedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ONBOARDED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ONBOARDED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSuggestedVendors() {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_SUGGESTED_VENDORS_REQUEST));

        vendorService.getSuggestedVendors().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SUGGESTED_VENDORS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleVendor(vendorId) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_SINGLE_VENDOR_REQUEST));

        vendorService.getSingleVendor(vendorId).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_SINGLE_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_SINGLE_VENDOR_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateGmail() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_GMAIL_REQUEST));

        vendorService.authenticateGmail().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_GMAIL_FAILURE, error?.message));
                }
            }
        );
    };
}

function connectGoogleWorkspace(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_GOOGLE_WORKSPACE_REQUEST));

        vendorService.connectGoogleWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.CONNECT_GOOGLE_WORKSPACE_FAILURE, error?.message));
                }
            }
        );
    };
}

function authenticateMicrosoftAcct() {
    return (dispatch) => {
        dispatch(request(vendorTypes.AUTHENTICATE_MICROSOFT_REQUEST));

        vendorService.authenticateMicrosoftAcct().then(
            (res) => {
                dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.AUTHENTICATE_MICROSOFT_FAILURE, error?.message));
                }
            }
        );
    };
}

function connectMicrosoftWorkspace(reqObj) {
    return (dispatch) => {
        dispatch(request(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_REQUEST));

        vendorService.connectMicrosoftWorkspace(reqObj).then(
            (res) => {
                dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function getAllChannels() {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_ALL_CHANNELS_REQUEST));

        vendorService.getAllChannels().then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_ALL_CHANNELS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(vendorTypes.FETCH_ALL_CHANNELS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getVendorsByOrderType(orderType = "supply", search) {
    return (dispatch) => {
        dispatch(request(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_REQUEST));

        vendorService.getVendorsByOrderType(orderType, search).then(
            (res) => {
                dispatch(success(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(
                        failure(vendorTypes.FETCH_VENDORS_BY_ORDER_TYPE_FAILURE, error?.message)
                    );
                }
            }
        );
    };
}

function resetConnectMicrosoftWorkspaceSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.CONNECT_MICROSOFT_WORKSPACE_SUCCESS, null));
    };
}

function resetAuthenticateMicrosoftSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_MICROSOFT_SUCCESS, null));
    };
}

function resetConnectGoogleWorkspaceSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.CONNECT_GOOGLE_WORKSPACE_SUCCESS, null));
    };
}

function resetAuthenticateGmailSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.AUTHENTICATE_GMAIL_SUCCESS, null));
    };
}

function resetGetSuggestedVendorsSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.FETCH_SUGGESTED_VENDORS_SUCCESS, null));
    };
}

function resetJoinWaitListSuccess() {
    return (dispatch) => {
        dispatch(success(vendorTypes.JOIN_WAITLIST_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
