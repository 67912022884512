import React from "react";

const ProgressBar = ({ progress, className }) => {
    return (
        <div className="h-1 w-full bg-gray-100">
            <div className={`h-full bg-carribean-green ${className}`} style={{ width: `${progress}%` }} />
        </div>
    );
};

export default ProgressBar;
