import React from "react";

const Checkbox = ({ name, required, isChecked, value, onChange, className }) => {
    return (
        <input
            type="checkbox"
            id={name}
            name={name}
            onChange={onChange}
            checked={isChecked}
            value={value}
            required={required}
            className={` ${className} relative border-slate-400 outline-none checked:border-0 h-4 w-4 shrink-0 appearance-none rounded-sm border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-[url('https://res.cloudinary.com/dvxi7qcmd/image/upload/v1691393902/check-line_1_wat7et.svg')] after:bg-[length:13px] cursor-pointer after:bg-center after:bg-no-repeat after:content-[''] checked:bg-carribean-green focus:outline-none`}
        />
    );
};

export default Checkbox;
