import { taskTypes } from "redux/Ldb/types";

const initialState = {
    createTask: false,
    createTaskSuccess: null,
    createTaskFailure: null,
    updateTask: false,
    updateTaskSuccess: null,
    updateTaskFailure: null,
    fetchingTaskList:false,
    fetchTaskSuccess: null,
    fetchTaskFailure: null,
};

export const task = (state = initialState, action) => {
    switch (action.type) {
        case taskTypes.CREATE_TASK_REQUEST:
            return {
                ...state,
                createTask: true,
                createTaskSuccess: null,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_SUCCESS:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: action.payload,
                createTaskFailure: null,
            };
        case taskTypes.CREATE_TASK_FAILURE:
            return {
                ...state,
                createTask: false,
                createTaskSuccess: null,
                createTaskFailure: action.payload,
            };

        case taskTypes.UPDATE_TASK_REQUEST:
            return {
                ...state,
                updateTask: true,
                updateTaskSuccess: null,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_SUCCESS:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: action.payload,
                updateTaskFailure: null,
            };
        case taskTypes.UPDATE_TASK_FAILURE:
            return {
                ...state,
                updateTask: false,
                updateTaskSuccess: null,
                updateTaskFailure: action.payload,
            };
        case taskTypes.FETCH_TASK_LIST_REQUEST:
            return {
                ...state,
                fetchingTaskList: true,
                fetchTaskSuccess: null,
                fetchTaskFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_SUCCESS:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskSuccess: action.payload,
                fetchTaskFailure: null,
            };
        case taskTypes.FETCH_TASK_LIST_FAILURE:
            return {
                ...state,
                fetchingTaskList: false,
                fetchTaskSuccess: null,
                fetchTaskFailure: action.payload,
            };
        default:
            return state;
    }
};
