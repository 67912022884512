import React, { Profiler, useEffect, useState } from "react";
import Dropdown from "../Dropdown";
import { authActions } from "redux/Ldb/actions";
import { useDispatch, useSelector } from "react-redux";
// import TextInput from "../InputField/TextInput";
import { truncate } from "lodash";
import { Link, useNavigate } from "react-router-dom";

const DashboardNav = () => {
    const [profile, setProfile] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { fetchUserProfileSuccess } = useSelector((state) => state.auth);

    const nameArr = profile?.profile?.businessName
        ? profile?.profile?.businessName?.split(" ")
        : [];
    const initials = nameArr
        ?.map((name) => name[0]?.toUpperCase())
        ?.join("")
        ?.slice(0, 3);

    useEffect(() => {
        if (!Boolean(fetchUserProfileSuccess)) {
            dispatch(authActions.getLdbProfile());
        }
    }, [dispatch, fetchUserProfileSuccess]);

    useEffect(() => {
        if (Boolean(fetchUserProfileSuccess)) {
            setProfile(fetchUserProfileSuccess);
        }
    }, [fetchUserProfileSuccess]);

    return (
        <nav className="w-full h-[84px] flex justify-between pr-[47px] pl-[34px] items-center bg-white border-b border-slate-100">
            <div className="mr-[56px]">
                <Link to={"/dashboard/vendors"}>
                    <img
                        src="https://res.cloudinary.com/dvxi7qcmd/image/upload/v1690905869/Web%20App/TradeAlly_logo_black_pf6fsn.svg"
                        alt="trade ally logo"
                        width={128}
                        height={24}
                    />
                </Link>
            </div>

            <div className="w-full flex items-center justify-end pl-[37px]">
                {/* <div className="w-[30%]">
                    <TextInput
                        name={"navSearch"}
                        type={"text"}
                        inputPlaceholder={"Search"}
                        inputClassName={"!h-[36px] pl-[0px] !mb-0 text-sm"}
                        leftIcon={
                            <i className="ri-search-line before:content-['\f0d1] text-slate-400"></i>
                        }
                        inputContainerClassname={
                            "!rounded-[4px] !pl-[40px] !bg-slate-50 border-slate-100 !h-[36px]"
                        }
                    />
                </div> */}
                <div className="flex items-center gap-4">
                    {/* <div className="cursor-pointer">
                        <i className="ri-question-line text-xl text-slate-700"></i>
                    </div>
                    <div className="relative cursor-pointer">
                        <i className="ri-chat-4-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div>
                    <div className="relative cursor-pointer">
                        <i className="ri-notification-3-fill text-xl text-slate-700"></i>
                        <p className="p-[2px] flex items-center justify-center absolute top-0 right-0 bg-r-50 rounded-[1px] text-white font-rocGroteskMedium text-[5px]">
                            12
                        </p>
                    </div> */}
                    <Dropdown
                        value={""}
                        dropdown={
                            <div className="grid grid-cols-[0.3fr_2fr_0.2fr] items-center gap-2 py-2.5 px-2.5 w-[224px] border rounded">
                                {Boolean(profile?.profile?.avatar) ? (
                                    <img
                                        src={profile?.profile?.avatar}
                                        alt="logo"
                                        className="rounded-full w-[22px] h-[22px] border-[1.5px] border-g-75"
                                    />
                                ) : (
                                    <div className=" rounded-full w-[22px] h-[22px] border-[1.5px] border-g-75 flex items-center justify-center text-[10px]">
                                        {initials}
                                    </div>
                                )}
                                <p className="text-sm capitalize">
                                    {truncate(profile?.profile?.businessName, { length: 20 })}
                                </p>
                                <div className="flex flex-col justify-center items-center gap-[1px] mt-[-21px]">
                                    <i className="ri-arrow-drop-up-fill h-[4px] before:content-['\ea55']"></i>
                                    <i className="ri-arrow-drop-down-fill h-[4px] before:content-['\ea4f']"></i>
                                </div>
                            </div>
                        }
                        dropdownContainerClasses={
                            " max-sm:left-[-274px] top-[50px] shadow-faintShadow rounded !py-0 !border-0"
                        }
                        dropdownClassName={"!max-h-fit"}
                        dropdownItemsClasses={
                            "border-b border-slate-100 text-slate-500 last:border-none !px-2.5 "
                        }
                        name={"quickAction"}
                        dropdownOptions={[
                            {
                                label: "Settings",
                                value: "settings",
                                icon: (
                                    <div className="bg-slate-50 w-[26px] h-[26px] rounded-full flex items-center justify-center">
                                        <i className="ri-settings-4-line text-slate-500"></i>
                                    </div>
                                ),
                                action: () => navigate("/dashboard/settings"),
                            },
                            {
                                label: "Logout",
                                value: "logout",
                                icon: (
                                    <div className="bg-slate-50 w-[26px] h-[26px] rounded-full flex items-center justify-center">
                                        <i className="ri-logout-circle-line text-[#FF7E68]"></i>
                                    </div>
                                ),
                                action: () => dispatch(authActions.logOut()),
                            },
                        ]}
                    />
                </div>
            </div>
        </nav>
    );
};

export default DashboardNav;
