export const findIndexWithLongestSubarray = (arr) => {
    if (!Array.isArray(arr)) {
        throw new Error("Input is not an array of arrays.");
    }

    let maxLength = -1; // Initialize the maximum length to a negative value
    let indexOfLongest = -1;

    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            const subArrayLength = arr[i].length;
            if (subArrayLength > maxLength) {
                maxLength = subArrayLength;
                indexOfLongest = i;
            }
        }
    }

    if (indexOfLongest === -1) {
        throw new Error("No subarrays found in the input array.");
    }

    return indexOfLongest;
};
