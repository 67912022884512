import CustomToast from "components/common/CustomToast";
import { authTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { authService } from "services/Ldb";
import { getFromStorage } from "helpers";

export const authActions = {
    register,
    verifyByMail,
    resendVerifyCode,
    loginUser,
    logOut,
    forgotPassword,
    resetPassword,
    changeVerificationEmail,
    updateToken,
    getLdbProfile,
    updateLdbProfile,
    resetProfileUpdate,
    addTeamMember,
    getTeamMembers,
    resetAddTeamMemberSuccess,
    addVendor,
    resetAddVendorSuccess,
    deleteTeamMember,
    updateTeamMember,
    resetDeleteTeamMemberSuccess,
    resetUpdateTeamMemberSuccess,
    changePassword,
    resetChangePasswordSuccess,
    updateUserProfile,
    resetUserUpdate,
    deleteLdbProfile,
    getRoles,
    createRoles,
    resetCreateRoles,
};

function register(reqObj) {
    return (dispatch) => {
        dispatch(request(authTypes.REGISTER_USER_REQUEST));
        authService.register(reqObj).then(
            (res) => {
                dispatch(success(authTypes.REGISTER_USER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.REGISTER_USER_FAILURE, error?.message));
                }
            }
        );
    };
}

function verifyByMail(code, token, remember) {
    return (dispatch) => {
        dispatch(request(authTypes.VERIFY_USER_REQUEST));
        authService.verifyByMail({ code }, token).then(
            (res) => {
                const user={
                    ...res.data,
                    verifyComplete:false
                }
                authService.saveAccount(user, remember);
                dispatch(success(authTypes.VERIFY_USER_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    // toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.VERIFY_USER_FAILURE, error.message));
                }
            }
        );
    };
}

function resendVerifyCode(showMessage = true, token) {
    return (dispatch) => {
        dispatch(request(authTypes.RESEND_VERIFY_CODE_REQUEST));
        authService.resendVerifyCode(token).then(
            (res) => {
                showMessage &&
                    toast.custom((t) => (
                        <CustomToast t={t} message={"Verify code resent to your mail."} />
                    ));
                dispatch(success(authTypes.RESEND_VERIFY_CODE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.RESEND_VERIFY_CODE_FAILURE, error?.message));
                }
            }
        );
    };
}

function loginUser(loginObj, remember) {
    return (dispatch) => {
        dispatch(request(authTypes.USER_LOGIN_REQUEST));
        authService.loginUser(loginObj).then(
            (res) => {
                const user = {
                    ...res?.data.data,
                    verifyComplete: true,
                };
                const storedUser = getFromStorage("ally-user");
                if (storedUser) {
                    storedUser.token = user.token; 
                    authService.saveAccount(storedUser, remember);
                }
                authService.saveAccount(user, remember);
                dispatch(success(authTypes.USER_LOGIN_SUCCESS, user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.USER_LOGIN_FAILURE, error?.message));
                }
            }
        );
    };
}

function getLdbProfile(token) {
    return (dispatch) => {
        dispatch(request(authTypes.FETCH_USER_PROFILE_REQUEST));

        authService.getLdbProfile(token).then(
            (res) => {
                dispatch(success(authTypes.FETCH_USER_PROFILE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.FETCH_USER_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateLdbProfile(data, token) {
    return (dispatch) => {
        dispatch(request(authTypes.UPDATE_LDB_PROFILE_REQUEST));
        authService.updateLdbProfile(data, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_LDB_PROFILE_SUCCESS, res?.data?.user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_LDB_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function updateUserProfile(data, token) {
    return (dispatch) => {
        dispatch(request(authTypes.UPDATE_USER_PROFILE_REQUEST));
        authService.updateUserProfile(data, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_USER_PROFILE_SUCCESS, res?.data?.user));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_USER_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteLdbProfile(token) {
    return (dispatch) => {
        dispatch(request(authTypes.DELETE_LDB_PROFILE_REQUEST));

        authService.deleteLdbProfile(token).then(
            (res) => {
                dispatch(success(authTypes.DELETE_LDB_PROFILE_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Account deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.DELETE_LDB_PROFILE_FAILURE, error?.message));
                }
            }
        );
    };
}
function forgotPassword(reqObj) {
    return (dispatch) => {
        dispatch(request(authTypes.FORGOT_PASSWORD_REQUEST));
        authService.forgetPassword(reqObj).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res?.message} />);
                dispatch(success(authTypes.FORGOT_PASSWORD_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.FORGOT_PASSWORD_FAILURE, error?.message));
                }
            }
        );
    };
}

function resetPassword(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.RESET_PASSWORD_REQUEST));
        authService.resetPassword(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res.message} />);
                dispatch(success(authTypes.RESET_PASSWORD_SUCCESS, res.success));
                window.location.href = "/login";
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.RESET_PASSWORD_FAILURE, error.message));
                }
            }
        );
    };
}
function changePassword(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.CHANGE_PASSWORD_REQUEST));
        authService.changePassword(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={res.message} />);
                dispatch(success(authTypes.CHANGE_PASSWORD_SUCCESS, res.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CHANGE_PASSWORD_FAILURE, error.message));
                }
            }
        );
    };
}
function changeVerificationEmail(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.CHANGE_SIGNUP_EMAIL_REQUEST));
        authService.changeVerificationEmail(reqObj, token).then(
            (res) => {
                toast.custom((t) => (
                    <CustomToast t={t} message={"Email updated," + res?.message} />
                ));
                dispatch(success(authTypes.CHANGE_SIGNUP_EMAIL_SUCCESS, res?.success));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CHANGE_SIGNUP_EMAIL_FAILURE, error.message));
                }
            }
        );
    };
}

function addTeamMember(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.ADD_TEAM_MEMBER_REQUEST));
        authService.addTeamMember(reqObj, token).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={"Team member added"} />);
                dispatch(success(authTypes.ADD_TEAM_MEMBER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.ADD_TEAM_MEMBER_FAILURE, error.message));
                }
            }
        );
    };
}
function updateTeamMember(reqObj, token) {
    return (dispatch) => {
        dispatch(request(authTypes.UPDATE_TEAM_MEMBER_REQUEST));

        authService.updateTeamMember(reqObj, token).then(
            (res) => {
                dispatch(success(authTypes.UPDATE_TEAM_MEMBER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Team member updated"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.UPDATE_TEAM_MEMBER_FAILURE, error?.message));
                }
            }
        );
    };
}

function deleteTeamMember(teamId, token) {
    return (dispatch) => {
        dispatch(request(authTypes.DELETE_TEAM_MEMBER_REQUEST));

        authService.deleteTeamMember(teamId, token).then(
            (res) => {
                dispatch(success(authTypes.DELETE_TEAM_MEMBER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Team member deleted"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(authTypes.DELETE_TEAM_MEMBER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getTeamMembers(teamLimit) {
    return (dispatch) => {
        dispatch(request(authTypes.FETCH_TEAM_MEMBER_REQUEST));

        authService.fetchTeamMembers(teamLimit).then(
            (res) => {
                dispatch(success(authTypes.FETCH_TEAM_MEMBER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.FETCH_TEAM_MEMBER_FAILURE, error.message));
                }
            }
        );
    };
}

function getRoles(limit, token, search) {
    return (dispatch) => {
        dispatch(request(authTypes.FETCH_ROLES_REQUEST));

        authService.getRoles(limit, token, search).then(
            (res) => {
                dispatch(success(authTypes.FETCH_ROLES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.FETCH_ROLES_FAILURE, error.message));
                }
            }
        );
    };
}

function createRoles(body, token) {
    return (dispatch) => {
        dispatch(request(authTypes.CREATE_ROLES_REQUEST));

        authService.createRoles(body, token).then(
            (res) => {
                dispatch(success(authTypes.CREATE_ROLES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.CREATE_ROLES_FAILURE, error.message));
                }
            }
        );
    };
}

function addVendor(reqObj) {
    return (dispatch) => {
        dispatch(request(authTypes.ADD_VENDOR_REQUEST));
        authService.addVendor(reqObj).then(
            (res) => {
                toast.custom((t) => <CustomToast t={t} message={"Invitation sent"} />);
                dispatch(success(authTypes.ADD_VENDOR_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(authTypes.ADD_VENDOR_FAILURE, error.message));
                }
            }
        );
    };
}

function resetProfileUpdate() {
    return (dispatch) => {
        dispatch(success(authTypes.UPDATE_LDB_PROFILE_SUCCESS, null));
    };
}
function resetCreateRoles() {
    return (dispatch) => {
        dispatch(success(authTypes.CREATE_ROLES_SUCCESS, null));
    };
}
function resetUserUpdate() {
    return (dispatch) => {
        dispatch(success(authTypes.UPDATE_USER_PROFILE_SUCCESS, null));
    };
}
function resetAddTeamMemberSuccess() {
    return (dispatch) => {
        dispatch(success(authTypes.ADD_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetChangePasswordSuccess() {
    return (dispatch) => {
        dispatch(success(authTypes.CHANGE_PASSWORD_SUCCESS, null));
    };
}
function resetDeleteTeamMemberSuccess() {
    return (dispatch) => {
        dispatch(success(authTypes.DELETE_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetUpdateTeamMemberSuccess() {
    return (dispatch) => {
        dispatch(success(authTypes.UPDATE_TEAM_MEMBER_SUCCESS, null));
    };
}

function resetAddVendorSuccess() {
    return (dispatch) => {
        dispatch(success(authTypes.ADD_VENDOR_SUCCESS, null));
    };
}

function updateToken(gFF) {
    authService.saveAccount(gFF);
    return request(authTypes.LOG_OUT);
}

function logOut() {
    authService.logout();
    return request(authTypes.LOG_OUT);
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
