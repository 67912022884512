import CustomToast from "components/common/CustomToast";
import { orderTypes } from "redux//Ldb/types";
import { toast } from "react-hot-toast";
import { orderService } from "services/Ldb";

export const orderActions = {
    getRfqs,
    getOrders,
    getSingleRfq,
    getSingleOrder,
    getQuotes,
    getSingleQuotes,
    getSingleQuoteResponses,
    acceptQuote,
    resetAcceptQuoteSuccess,
    updateOrder,
    resetUpdateOrderSuccess,
    resetSendPurchaseOrderSuccess,
    updateRfq,
    resetUpdateRfqSuccess,
    sendRfqs,
    resetCreatePurchaseOrderSuccess,
    resetSendRfqsSuccess,
    deleteRfq,
    resetDeleteRfqSuccess,
    createPurchaseOrder,
    updatePurchaseOrder,
    getPurchaseOrder,
    getSinglePurchaseOrder,
    sendPurchaseOrder,
    getApprovedResponses,
    getSingleQuoteResponse,
    resetUpdatePurchaseOrderSuccess,
};

function getRfqs(rfqLimit, search, vendor, orderId, amount) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_RFQS_REQUEST));

        orderService.getRfqs(rfqLimit, search, vendor, orderId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_RFQS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getOrders(orderLimit) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_ORDERS_REQUEST));

        orderService.getOrders(orderLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_ORDERS_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_ORDERS_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleRfq(rfqId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_RFQ_REQUEST));

        orderService.getSingleRfq(rfqId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_RFQ_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleOrder(orderId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_ORDER_REQUEST));

        orderService.getSingleOrder(orderId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getQuotes(quoteLimit, search, vendor, quoteId, amount) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_QUOTES_REQUEST));

        orderService.getQuotes(quoteLimit, search, vendor, quoteId, amount).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}
function updateOrder(orderId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_ORDER_REQUEST));

        orderService.updateOrder(orderId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getSingleQuotes(quoteId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_QUOTES_REQUEST));

        orderService.getSingleQuotes(quoteId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_QUOTES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}
function updateRfq(rfqId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_RFQ_REQUEST));

        orderService.updateRfq(rfqId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, res?.data));
                toast.custom((t) => (
                    <CustomToast t={t} message={"Updated successfully"} type="success" />
                ));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function getApprovedResponses(limit, search) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_APPROVED_RESPONSE_REQUEST));

        orderService.getApprovedResponses(limit, search).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_APPROVED_RESPONSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_APPROVED_RESPONSE_FAILURE, error.message));
                }
            }
        );
    };
}

function getSingleQuoteResponses(quoteId, responseLimit) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_REQUEST));

        orderService.getSingleQuoteResponses(quoteId, responseLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_QUOTE_RESPONSES_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_QUOTES_FAILURE, error.message));
                }
            }
        );
    };
}

function sendRfqs(data) {
    return (dispatch) => {
        dispatch(request(orderTypes.SEND_RFQS_REQUEST));

        orderService.sendRfqs(data).then(
            (res) => {
                dispatch(success(orderTypes.SEND_RFQS_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={"RFQs sent"} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.SEND_RFQS_FAILURE, error?.message));
                }
            }
        );
    };
}

function acceptQuote(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.ACCEPT_QUOTE_REQUEST));

        orderService.acceptQuote(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.ACCEPT_QUOTE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.ACCEPT_QUOTE_FAILURE, error.message));
                }
            }
        );
    };
}

function deleteRfq(rfqId) {
    return (dispatch) => {
        dispatch(request(orderTypes.DELETE_RFQ_REQUEST));

        orderService.deleteRfq(rfqId).then(
            (res) => {
                dispatch(success(orderTypes.DELETE_RFQ_SUCCESS, res?.data));
                toast.custom((t) => <CustomToast t={t} message={"RFQ deleted"} type="success" />);
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.DELETE_RFQ_FAILURE, error?.message));
                }
            }
        );
    };
}

function createPurchaseOrder(reqObj) {
    return (dispatch) => {
        dispatch(request(orderTypes.CREATE_PURCHASE_ORDER_REQUEST));

        orderService.createPurchaseOrder(reqObj).then(
            (res) => {
                dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.CREATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function updatePurchaseOrder(poId, data) {
    return (dispatch) => {
        dispatch(request(orderTypes.UPDATE_PURCHASE_ORDER_REQUEST));

        orderService.updatePurchaseOrder(poId, data).then(
            (res) => {
                dispatch(success(orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.UPDATE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}

function getPurchaseOrder(taId, search, poLimit) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_PURCHASE_ORDER_REQUEST));

        orderService.getPurchaseOrder(taId, search, poLimit).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function getSinglePurchaseOrder(orderId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_REQUEST));

        orderService.getSinglePurchaseOrder(orderId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => (
                        <CustomToast t={t} message={error?.message} type="error" />
                    ));
                    dispatch(failure(orderTypes.FETCH_SINGLE_PURCHASE_ORDER_FAILURE, error?.message));
                }
            }
        );
    };
}


function sendPurchaseOrder(taId, body) {
    return (dispatch) => {
        dispatch(request(orderTypes.SEND_PURCHASE_ORDER_REQUEST));

        orderService.sendPurchaseOrder(taId, body).then(
            (res) => {
                dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.SEND_PURCHASE_ORDER_FAILURE, error.message));
                }
            }
        );
    };
}

function getSingleQuoteResponse(quoteId) {
    return (dispatch) => {
        dispatch(request(orderTypes.FETCH_SINGLE_RESPONSE_REQUEST));

        orderService.getSingleQuoteResponse(quoteId).then(
            (res) => {
                dispatch(success(orderTypes.FETCH_SINGLE_RESPONSE_SUCCESS, res?.data));
            },
            (error) => {
                if (error.message) {
                    toast.custom((t) => <CustomToast t={t} message={error.message} type="error" />);
                    dispatch(failure(orderTypes.FETCH_SINGLE_RESPONSE_FAILURE, error.message));
                }
            }
        );
    };
}

function resetAcceptQuoteSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.ACCEPT_QUOTE_SUCCESS, null));
    };
}

function resetUpdateOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_ORDER_SUCCESS, null));
    };
}

function resetCreatePurchaseOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.CREATE_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetUpdatePurchaseOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_PURCHASE_ORDER_SUCCESS, null));
    };
}
function resetSendPurchaseOrderSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.SEND_PURCHASE_ORDER_SUCCESS, null));
    };
}

function resetUpdateRfqSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.UPDATE_RFQ_SUCCESS, null));
    };
}

function resetSendRfqsSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.SEND_RFQS_SUCCESS, null));
    };
}

function resetDeleteRfqSuccess() {
    return (dispatch) => {
        dispatch(success(orderTypes.DELETE_RFQ_SUCCESS, null));
    };
}

function request(type) {
    return { type: type };
}
function success(type, data) {
    return { type: type, payload: data };
}
function failure(type, error) {
    return { type: type, payload: error ?? "" };
}
