import { settingsTypes } from "redux/Ldb/types";

const initialState = {
    fetchingPaymentHistory: false,
    fetchPaymentHistorySuccess: null,
    fetchPaymentHistoryFailure: null,
    fetchingCreditHistory: false,
    fetchCreditHistorySuccess: null,
    fetchCreditHistoryFailure: null,
    fetchingSubscriptionPlan: false,
    fetchSubscriptionPlanSuccess: null,
    fetchSubscriptionPlanFailure: null,
    fetchingCurrentSubscription: false,
    fetchCurrentSubscriptionSuccess: null,
    fetchCurrentSubscriptionFailure: null,
    updateStripeSession: false,
    updateStripeSessionSuccess: null,
    updateStripeSessionFailure: null,
    createStripeSession: false,
    createStripeSessionSuccess: null,
    createStripeSessionFailure: null,
    fetchStripeSession: false,
    fetchStripeSessionSuccess: null,
    fetchStripeSessionFailure: null,
};

export const settings = (state = initialState, action) => {
    switch (action.type) {
        case settingsTypes.FETCH_PAYMENT_HISTORY_REQUEST:
            return {
                ...state,
                fetchingPaymentHistory: true,
                fetchPaymentHistorySuccess: null,
                fetchPaymentHistoryFailure: null,
            };
        case settingsTypes.FETCH_PAYMENT_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingPaymentHistory: false,
                fetchPaymentHistorySuccess: action.payload,
                fetchPaymentHistoryFailure: null,
            };
        case settingsTypes.FETCH_PAYMENT_HISTORY_FAILURE:
            return {
                ...state,
                fetchingPaymentHistory: false,
                fetchPaymentHistorySuccess: null,
                fetchPaymentHistoryFailure: action.payload,
            };
        case settingsTypes.FETCH_CREDIT_HISTORY_REQUEST:
            return {
                ...state,
                fetchingCreditHistory: true,
                fetchCreditHistorySuccess: null,
                fetchCreditHistoryFailure: null,
            };
        case settingsTypes.FETCH_CREDIT_HISTORY_SUCCESS:
            return {
                ...state,
                fetchingCreditHistory: false,
                fetchCreditHistorySuccess: action.payload,
                fetchCreditHistoryFailure: null,
            };
        case settingsTypes.FETCH_CREDIT_HISTORY_FAILURE:
            return {
                ...state,
                fetchingCreditHistory: false,
                fetchCreditHistorySuccess: null,
                fetchCreditHistoryFailure: action.payload,
            };
        case settingsTypes.FETCH_PLANS_REQUEST:
            return {
                ...state,
                fetchingSubscriptionPlan: true,
                fetchSubscriptionPlanSuccess: null,
                ffetchSubscriptionPlanFailure: null,
            };
        case settingsTypes.FETCH_PLANS_SUCCESS:
            return {
                ...state,
                fetchingSubscriptionPlan: false,
                fetchSubscriptionPlanSuccess: action.payload,
                fetchSubscriptionPlanFailure: null,
            };
        case settingsTypes.FETCH_PLANS_FAILURE:
            return {
                ...state,
                createStripeSession: false,
                createStripeSessionSuccess: null,
                createStripeSessionFailure: action.payload,
            };

        case settingsTypes.CREATE_STRIPE_SESSION_REQUEST:
            return {
                ...state,
                createStripeSession: true,
                createStripeSessionSuccess: null,
                createStripeSessionFailure: null,
            };
        case settingsTypes.CREATE_STRIPE_SESSION_SUCCESS:
            return {
                ...state,
                createStripeSession: false,
                createStripeSessionSuccess: action.payload,
                createStripeSessionFailure: null,
            };
        case settingsTypes.CREATE_STRIPE_SESSION_FAILURE:
            return {
                ...state,
                createStripeSession: false,
                createStripeSessionSuccess: null,
                createStripeSessionFailure: action.payload,
            };
        case settingsTypes.UPDATE_STRIPE_SESSION_REQUEST:
            return {
                ...state,
                updateStripeSession: true,
                updateStripeSessionSuccess: null,
                updateStripeSessionFailure: null,
            };
        case settingsTypes.UPDATE_STRIPE_SESSION_SUCCESS:
            return {
                ...state,
                updateStripeSession: false,
                updateStripeSessionSuccess: action.payload,
                updateStripeSessionFailure: null,
            };
        case settingsTypes.UPDATE_STRIPE_SESSION_FAILURE:
            return {
                ...state,
                updateStripeSession: false,
                updateStripeSessionSuccess: null,
                updateStripeSessionFailure: action.payload,
            };
        case settingsTypes.FETCH_CREDIT_STRIPE_SESSION_REQUEST:
            return {
                ...state,
                fetchStripeSession: true,
                fetchStripeSessionSuccess: null,
                fetchStripeSessionFailure: null,
            };
        case settingsTypes.FETCH_CREDIT_STRIPE_SESSION_SUCCESS:
            return {
                ...state,
                fetchStripeSession: false,
                fetchStripeSessionSuccess: action.payload,
                fetchStripeSessionFailure: null,
            };
        case settingsTypes.FETCH_CREDIT_STRIPE_SESSION_FAILURE:
            return {
                ...state,
                fetchStripeSession: false,
                fetchStripeSessionSuccess: null,
                fetchStripeSessionFailure: action.payload,
            };
        case settingsTypes.FETCH_CURRENT_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                fetchingCurrentSubscription: true,
                fetchCurrentSubscriptionSuccess: null,
                fetchCurrentSubscriptionFailure: null,
            };
        case settingsTypes.FETCH_CURRENT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                fetchingCurrentSubscription: false,
                fetchCurrentSubscriptionSuccess: action.payload,
                fetchCurrentSubscriptionFailure: null,
            };
        case settingsTypes.FETCH_CURRENT_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                fetchingCurrentSubscription: false,
                fetchCurrentSubscriptionSuccess: null,
                fetchCurrentSubscriptionFailure: action.payload,
            };
        default:
            return state;
    }
};
