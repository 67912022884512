import config from "config/config";
import { authService } from "./authService";

export const settingsService = {
    fetchPaymentHistory,
    fetchSubscriptionPlan,
    fetchCreditUsageHistory,
    fetchCreditStripeSession,
    createStripeSession,
    fetchCurrentSubscription,
    updateStripeSession,
};

async function fetchPaymentHistory() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/payment/history`,
        requestOptions
    );
    return res;
}

async function fetchCreditUsageHistory() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/credit/usage-history`,
        requestOptions
    );
    return res;
}

async function fetchCreditStripeSession(creditUnit) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/billing/get/credit-session?credit_units=${creditUnit}`,
        requestOptions
    );
    return res;
}

async function fetchCurrentSubscription() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/billing/subscription`,
        requestOptions
    );
    return res;
}

async function fetchSubscriptionPlan(interval) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/billing/get/plans?interval=${interval}`,
        requestOptions
    );
    return res;
}

async function createStripeSession(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/billing/create/subscription-session`,
        requestOptions
    );
    return res;
}
async function updateStripeSession(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/billing/update/subscription`,
        requestOptions
    );
    return res;
}
