import Loader from "components/common/Loader";
import { PrivateRoutes } from "components/common/Routes/PrivateRoute";
import { PublicRoutes } from "components/common/Routes/PublicRoute";
import NoMatchPage from "pages/Ldb/404Page";
import Dashboard from "pages/Ldb/Dashboard";
// import WaitList from "pages/Landing/WaitList";
import { Suspense, lazy } from "react";
// import Terms from "pages/Landing/Terms";
// import Policy from "pages/Landing/Policy";

// Public Pages
const Home = lazy(() => import("pages/Landing/Home"));
const WaitList = lazy(() => import("pages/Landing/WaitList"));
const Terms = lazy(() => import("pages/Landing/Terms"));
const Policy = lazy(() => import("pages/Landing/Policy"));
const Login = lazy(() => import("pages/Ldb/Auth/Login"));
const Signup = lazy(() => import("pages/Ldb/Auth/Signup"));
const ForgotPassword = lazy(() => import("pages/Ldb/Auth/ForgotPassword"));
const ResetPassword = lazy(() => import("pages/Ldb/Auth/ResetPassword"));
const SignupSuccess = lazy(() => import("pages/Ldb/Auth/SignupSuccess"));
const AuthSteps = lazy(() => import("pages/Ldb/Auth/AuthSteps"));

const AcctVerification = lazy(() => import("pages/Ldb/Auth/AcctVerification"));

const ChangeVerificationEmail = lazy(() => import("pages/Ldb/Auth/ChangeVerificationEmail"));

const NewOnboard = lazy(() => import("pages/Ldb/Onboarding/newOnboard"));

const GoogleAuth = lazy(() => import("pages/Ldb/Auth/GoogleAuth"));

// Private Pages
// const Dashboard = lazy(() => import("pages/Ldb/Dashboard"));
const DashboardHome = lazy(() => import("pages/Ldb/Home"));
const VendorList = lazy(() => import("pages/Ldb/VendorManagement/VendorList"));
const Shipment = lazy(() => import("pages/Ldb/Shipment/index"));
const ShipmentDetails = lazy(() => import("pages/Ldb/Shipment/ShipmentDetails"));
const VendorDetails = lazy(() => import("pages/Ldb/VendorManagement/VendorDetails"));
const AddVendor = lazy(() => import("pages/Ldb/VendorManagement/AddVendor"));
const VendorAnalytics = lazy(() => import("pages/Ldb/VendorManagement/VendorAnalytics"));
const Rfqs = lazy(() => import("pages/Ldb/Orders/Rfqs"));
const Copilot = lazy(() => import("pages/Ldb/Copilot"));
const OrderDetails = lazy(() => import("pages/Ldb/Orders/Rfqs/OrderDetails"));
const RfqDetails = lazy(() => import("pages/Ldb/Orders/Rfqs/RfqDetails"));
const Quotes = lazy(() => import("pages/Ldb/Orders/Quotes/index"));
const QuoteDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteDetails"));
const QuoteItemDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteItemDetails"));
const QuoteVendorDetails = lazy(() => import("pages/Ldb/Orders/Quotes/QuoteVendorDetails"));
const TeamManagement = lazy(() => import("pages/Ldb/TeamManagement/index"));
const Workflow = lazy(() => import("pages/Ldb/Workflow/index"));
const WorkflowRun = lazy(() => import("pages/Ldb/Workflow/WorkFlowRun"));
const WorkflowGraph = lazy(() => import("pages/Ldb/Workflow/WorkflowGraph"));
const Settings = lazy(() => import("pages/Ldb/Settings"));
const PurchaseOrder = lazy(() => import("pages/Ldb/Orders/PurchaseOrder/index"));
const PurchaseOrderDetail = lazy(() =>
    import("pages/Ldb/Orders/PurchaseOrder/PurchaseOrderDetails")
);
const CreatePurchaseOrder = lazy(() => import("pages/Ldb/Orders/PurchaseOrder/CreateOrders"));
const EditPurchaseOrder = lazy(() => import("pages/Ldb/Orders/PurchaseOrder/EditOrders"));

const CreateRfqs = lazy(() => import("pages/Ldb/Orders/Rfqs/CreateRfqs"));
const Tasks = lazy(() => import("pages/Ldb/Task/index"));

const routes = [
    {
        path: "/",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <Home />
            </Suspense>
        ),
    },

    {
        path: "/waitlist",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                {" "}
                <WaitList />{" "}
            </Suspense>
        ),
    },

    {
        path: "/terms",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                {" "}
                <Terms />{" "}
            </Suspense>
        ),
    },

    {
        path: "/policy",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <Policy />{" "}
            </Suspense>
        ),
    },

    {
        path: "/auth-steps",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PrivateRoutes>
                    <AuthSteps />
                </PrivateRoutes>
            </Suspense>
        ),
    },
    {
        path: "/login",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <Login />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/register",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <Signup />
                </PublicRoutes>
            </Suspense>
        ),
    },

    {
        path: "/forgot-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ForgotPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/reset-password",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ResetPassword />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/registration-success/:token",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <SignupSuccess />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/new-onboard",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PrivateRoutes>
                    <NewOnboard />
                </PrivateRoutes>
            </Suspense>
        ),
    },
    {
        path: "/verify",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <AcctVerification />
                </PublicRoutes>
            </Suspense>
        ),
    },
    {
        path: "/change-email/:token",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <ChangeVerificationEmail />
                </PublicRoutes>
            </Suspense>
        ),
    },

    {
        path: "/google-auth",
        element: (
            <Suspense
                fallback={
                    <div className="h-screen flex items-center justify-center">
                        <Loader color={"gm-50"} size={8} />
                    </div>
                }
            >
                <PublicRoutes>
                    <GoogleAuth />
                </PublicRoutes>
            </Suspense>
        ),
    },

    {
        path: "/dashboard",
        element: <Dashboard />,
        children: [
            {
                index: true,
                element: <DashboardHome />,
            },
            {
                path: "workflow",
                element: <Workflow />,
            },
            {
                path: "workflow/run/:workflowId",
                element: <WorkflowRun />,
            },
            {
                path: "workflow/:workflowId",
                element: <WorkflowGraph />,
            },
            {
                path: "task",
                element: <Tasks />,
            },
            {
                path: "vendors",
                element: <VendorList />,
            },
            {
                path: "vendors/add-vendor",
                element: <AddVendor />,
            },
            {
                path: "vendor/:vendorId",
                element: <VendorDetails />,
            },
            {
                path: "vendors/analytics",
                element: <VendorAnalytics />,
            },
            {
                path: "shipment",
                element: <Shipment />,
            },
            {
                path: "shipment/:shipmentId",
                element: <ShipmentDetails />,
            },
            {
                path: "rfqs",
                element: <Rfqs />,
            },
            {
                path: "co-pilot",
                element: <Copilot />,
            },
            {
                path: "quotes",
                element: <Quotes />,
            },
            {
                path: "quotes/:quoteId",
                element: <QuoteDetails />,
            },
            {
                path: "quotes/:quoteId/responses",
                element: <QuoteItemDetails />,
            },
            {
                path: "order/:orderId",
                element: <OrderDetails />,
            },
            {
                path: "quotes/:quoteId/item/:itemId/quote-detail/:id",
                element: <QuoteVendorDetails />,
            },
            {
                path: "purchase-orders",
                element: <PurchaseOrder />,
            },
            {
                path: "purchase-orders/:poId",
                element: <PurchaseOrderDetail />,
            },
            {
                path: "purchase-orders/edit",
                element: <EditPurchaseOrder />,
            },
            {
                path: "purchase-orders/create",
                element: <CreatePurchaseOrder />,
            },
            {
                path: "rfq/:rfqId",
                element: <RfqDetails />,
            },
            {
                path: "rfqs/create",
                element: <CreateRfqs />,
            },
            {
                path: "team-management",
                element: <TeamManagement />,
            },
            {
                path: "settings",
                element: <Settings />,
            }
        ],
    },
    {
        path: "*",
        element: <NoMatchPage />,
    },
];

export default routes;
