/* eslint-disable no-unused-vars */
import { getFromStorage, removeFromStorage } from "helpers";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { authService } from "services/Ldb";

export const PublicRoutes = ({ children }) => {
    const location = useLocation();
    const [profile, setProfile] = useState(() => getFromStorage("ally-user"));
    const redirectUrl = getFromStorage("redirect-url");

    const hasOnboarded =
        Boolean(profile?.user?.profile?.businessName) &&
        Boolean(profile?.user?.profile?.country) &&
        Boolean(profile?.user?.profile?.businessType) &&
        Boolean(profile?.user?.fullName) &&
        Boolean(profile?.user?.profile?.address);

    const handleStorageChange = () => {
        setProfile(getFromStorage("ally-user"));
    };

    useEffect(() => {
        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    if (
        !profile?.user?.active &&
        authService.loggedIn() &&
        location.pathname !== `/registration-success/${profile?.token}` &&
        profile?.token &&
        !profile?.verifyComplete
    ) {
        return <Navigate to={`/registration-success/${profile?.token}?resend=true`} replace />;
    }

    if (profile?.user?.active && authService.loggedIn() && hasOnboarded) {
        return (
            <Navigate
                to={
                    redirectUrl && redirectUrl !== "/new-onboard"
                        ? redirectUrl
                        : "/dashboard/vendors"
                }
                replace
            />
        );
    }

    return children;
};
