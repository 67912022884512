import React from "react";
import Loader from "./Loader";

const Button = ({
    btnText,
    type,
    onClick,
    btnClassname,
    btnCustomContent,
    isLoading,
    disabled,
    icon,
    btnTextClassName,
    btnType,
}) => {
    return (
        <button
            onClick={(e) => onClick?.(e)}
            type={type ? type : "button"}
            disabled={disabled}
            className={`${btnClassname} w-full flex justify-center py-3 items-center px-4
             bg-carribean-green rounded text-sm font-rocGroteskMedium text-white`}
        >
            {isLoading ? (
                <Loader color={"inherit"} size={4} />
            ) : btnCustomContent ? (
                btnCustomContent
            ) : (
                <>
                    {btnType === "textFirst" ? (
                        <div className="flex space-x-2 items-center">
                            <p className={btnTextClassName}> {btnText}</p>
                            <div> {icon}</div>
                        </div>
                    ) : icon ? (
                        <div className="flex space-x-2 items-center">
                            <div> {icon}</div>
                            <p className={btnTextClassName}> {btnText}</p>
                        </div>
                    ) : (
                        btnText
                    )}
                </>
            )}
        </button>
    );
};

export default Button;
