import config from "config/config";
import { authService } from "./authService";
import { createUrlSearchParams } from "helpers";

export const workFlowService = {
    getWorkFlowList,
    createWorkFlow,
    deleteWorkFlow,
    pauseOrPlayWorkFlow,
    createEventAndAction,
    getSingleWorkflow,
    updateWorkflowConnections,
    updateWorkflowDisconnections,
    updateWorkflowNodePosition,
    createComment,
    getNodeComments,
    updateWorkflowEvent,
    deleteEvent,
    deleteAction,
    swapNodes,
    getEventAndActionsOptionList,
    getWorkflowActivities,
    duplicateWorkflow,
    updateWorkflowName,
    getWorkflowRuns,
    getSingleWorkflowRun,
    retryAction,
    markActionAsDone,
    playPauseWorkflowRun,
    getWorkflowAnalytics,
    getDashboardWorkflowRuns,
};

async function getWorkFlowList(workflowLimit, WorkflowName, createdBy, numOfRuns, dateCreated) {
    const requestOptions = {
        method: "GET",
    };
    const urlParams = createUrlSearchParams({
        WorkflowName,
        createdBy,
        numOfRuns,
        dateCreated,
    });

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list?limit=${workflowLimit}${
            Boolean(urlParams?.toString()) ? "&" + urlParams?.toString() : ""
        }`,
        requestOptions
    );
    return res;
}

async function getDashboardWorkflowRuns() {
    const requestOptions = {
        method: "GET",
    };
   
    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/dashboard/list/runs`,
        requestOptions
    );
    return res;
}


async function createWorkFlow(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(`${config.API_URL}/api/v1/ldb/workFlow`, requestOptions);

    return res;
}

async function createEventAndAction(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event`,
        requestOptions
    );

    return res;
}

async function getSingleWorkflow(workflowId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/single-workFlow/${workflowId}`,
        requestOptions
    );

    return res;
}

async function deleteWorkFlow(workFlowId) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/delete/workFlow/${workFlowId}`,
        requestOptions
    );
    return res;
}

async function pauseOrPlayWorkFlow(workflowId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/play-or-pause/workFlow/${workflowId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowEvent(eventId, data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/action/${eventId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowConnections(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/connection`,
        requestOptions
    );
    return res;
}

async function updateWorkflowDisconnections(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/disconnect`,
        requestOptions
    );
    return res;
}

async function updateWorkflowNodePosition(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/position`,
        requestOptions
    );
    return res;
}

async function createComment(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment`,
        requestOptions
    );
    return res;
}

async function getNodeComments(nodeId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/comment/node/${nodeId}`,
        requestOptions
    );
    return res;
}

async function deleteEvent(eventId) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event/${eventId}`,
        requestOptions
    );
    return res;
}

async function deleteAction(actionId) {
    const requestOptions = {
        method: "DELETE",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/action/${actionId}`,
        requestOptions
    );
    return res;
}

async function swapNodes(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/swap`,
        requestOptions
    );
    return res;
}

async function getEventAndActionsOptionList() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/event-actions`,
        requestOptions
    );
    return res;
}

async function getWorkflowActivities(workflowId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list/activities/${workflowId}?limit=20`,
        requestOptions
    );
    return res;
}

async function duplicateWorkflow(workflowId) {
    const requestOptions = {
        method: "POST",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/duplicate-workflow/${workflowId}`,
        requestOptions
    );
    return res;
}

async function updateWorkflowName(data) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/update/workflow/name`,
        requestOptions
    );
    return res;
}

async function getWorkflowRuns(workFlowId, limit) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/list/runs?workFlowId=${workFlowId}&limit=${limit}`,
        requestOptions
    );
    return res;
}

async function getSingleWorkflowRun(workFlowRunId) {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/single-workFlow-run/${workFlowRunId}`,
        requestOptions
    );
    return res;
}

async function retryAction(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/retry-action`,
        requestOptions
    );
    return res;
}

async function markActionAsDone(data) {
    const requestOptions = {
        method: "POST",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/run/action-done`,
        requestOptions
    );
    return res;
}

async function playPauseWorkflowRun(data, runId) {
    const requestOptions = {
        method: "PUT",
        body: JSON.stringify(data),
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/workFlow/play-or-pause/run/${runId}`,
        requestOptions
    );
    return res;
}

async function getWorkflowAnalytics() {
    const requestOptions = {
        method: "GET",
    };

    const res = await authService.apiGate(
        `${config.API_URL}/api/v1/ldb/analytics/workflow`,
        requestOptions
    );
    return res;
}
